(function(module) {
try {
  module = angular.module('pipSupport.Templates');
} catch (e) {
  module = angular.module('pipSupport.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('feedback/feedback.html',
    '<!--\n' +
    '@file Feedback page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-toolbar class="pip-appbar-ext"></md-toolbar>\n' +
    '<pip-document width="800">\n' +
    '\n' +
    '    <pip-feedback-panel data="data" pip-created="$panel = $control"\n' +
    '                        show-pictures="showPictures"\n' +
    '                        show-documents="showDocuments"\n' +
    '                        save-callback="saveCallback"\n' +
    '                        type-collection="typeCollection"></pip-feedback-panel>\n' +
    '\n' +
    '    <div class="pip-footer">\n' +
    '        <pip-content-switch class="hide-xs"></pip-content-switch>\n' +
    '\n' +
    '        <div class="flex"></div>\n' +
    '\n' +
    '        <div class="flex-fixed layout-row">\n' +
    '            <md-button ng-show="transaction.busy()" ng-click="transaction.abort()" class="md-raised md-warn">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '            <md-button ng-hide="transaction.busy()" ng-click="goBack()">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '            <md-button class="md-accent" ng-hide="transaction.busy()" ng-click="onSave()"\n' +
    '                       ng-disabled="data.content == \'\' && data.title == \'\'">\n' +
    '                {{::\'SEND\' | translate}}\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</pip-document>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipSupport.Templates');
} catch (e) {
  module = angular.module('pipSupport.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('feedback/feedback_dialog.html',
    '<!--\n' +
    '@file Feedback dialog\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-dialog width="800" class="pip-feedback-dialog">\n' +
    '    <md-dialog-content class="lp24-flex rp24-flex">\n' +
    '        <pip-feedback-panel data="data" pip-created="$panel = $control"\n' +
    '                            show-pictures="showPictures"\n' +
    '                            show-documents="showDocuments"\n' +
    '                            save-callback="saveCallback"\n' +
    '                            type-collection="typeCollection"></pip-feedback-panel>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions class="layout-row">\n' +
    '        <pip-content-switch class="show-gt-sm"></pip-content-switch>\n' +
    '\n' +
    '        <div class="flex"></div>\n' +
    '\n' +
    '        <div class="layout-row flex-fixed">\n' +
    '            <md-button ng-show="transaction.busy()" ng-click="transaction.abort()" class="md-raised md-warn">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '            <md-button ng-hide="transaction.busy()" ng-click="goBack()">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '            <md-button class="md-accent rm8" ng-hide="transaction.busy()" ng-click="onSave()"\n' +
    '                       ng-disabled="data.content == \'\' && data.title == \'\'">\n' +
    '                {{::\'SEND\' | translate}}\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipSupport.Templates');
} catch (e) {
  module = angular.module('pipSupport.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('feedback/feedback_panel.html',
    '<div class="pip-body tp24-flex">\n' +
    '    <div class="pip-content layout-column">\n' +
    '        <md-progress-linear ng-show="transaction.busy()" md-mode="indeterminate"\n' +
    '                            class="pip-progress-ontop"></md-progress-linear>\n' +
    '\n' +
    '        <form name="form" novalidate>\n' +
    '            <md-input-container class="md-block hide-gt-xs">\n' +
    '                <md-select ng-model="typeIndex" ng-disabled="transaction.busy()" aria-label="DROPDOWN">\n' +
    '\n' +
    '                    <md-option ng-repeat="action in typeCollection" value="{{ ::$index }}">\n' +
    '                        {{ (action.title || action.name) | translate }}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <pip-toggle-buttons ng-model="data.type"\n' +
    '                                pip-buttons="typeCollection"\n' +
    '                                class="bm16 hide-xs">\n' +
    '            </pip-toggle-buttons>\n' +
    '            <p class="tm0 bm16 text-small text-grey line-height-string"\n' +
    '               ng-if="data.type==\'feedback\' || data.type==\'support\'">\n' +
    '                {{::\'FEEDBACK_HINT_FEEDBACK\' | translate}}\n' +
    '            </p>\n' +
    '\n' +
    '            <div ng-if="data.type==\'copyright\'">\n' +
    '                <p class="tm0 bm16 text-small text-grey line-height-string">\n' +
    '                    {{::\'FEEDBACK_HINT_COPYRIGHT\' | translate}}\n' +
    '                </p>\n' +
    '\n' +
    '                <p class="tm0 bm16 text-small text-grey line-height-string">\n' +
    '                    {{::\'FEEDBACK_HINT_COPYRIGHT2\' | translate }}\n' +
    '                </p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="pip-ref-item">\n' +
    '                <pip-avatar pip-party-id="$party.id"\n' +
    '                            pip-party-name="$party.name"\n' +
    '                            class="pip-pic pip-face"></pip-avatar>\n' +
    '                <div class="pip-content">\n' +
    '                    <p class="pip-title">{{$party.name}} </p>\n' +
    '\n' +
    '                    <p class="pip-subtitle">{{$party.email}}</p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="data.type!=\'copyright\'">\n' +
    '                <md-input-container class="md-block" md-no-float>\n' +
    '                    <input type="text" ng-model="data.title"\n' +
    '                           ng-disabled="transaction.busy()"\n' +
    '                           placeholder="{{:: \'SUBJECT\' | translate}}"/>\n' +
    '                </md-input-container>\n' +
    '                <md-input-container class="md-block" md-no-float>\n' +
    '                        <textarea ng-model="data.content" ng-disabled="transaction.busy()"\n' +
    '                                  placeholder="{{::\'FEEDBACK_MESSAGE\'|translate}}">\n' +
    '                        </textarea>\n' +
    '                </md-input-container>\n' +
    '            </div>\n' +
    '\n' +
    '            <div ng-if="data.type==\'copyright\'">\n' +
    '                <md-input-container class="md-block">\n' +
    '                    <label>{{::\'FEEDBACK_COMPANY\'|translate}}</label>\n' +
    '                    <input type="text" ng-model="data.company_name"\n' +
    '                           ng-disabled="transaction.busy()" placeholder="Company Name"/>\n' +
    '                </md-input-container>\n' +
    '\n' +
    '                <md-input-container class="md-block">\n' +
    '                    <label>{{::\'FEEDBACK_ADDRESS\'|translate}}</label>\n' +
    '                    <input type="text" ng-model="data.company_addr"\n' +
    '                           ng-disabled="transaction.busy()"/>\n' +
    '                </md-input-container>\n' +
    '\n' +
    '                <md-input-container class="md-block">\n' +
    '                    <label>{{::\'FEEDBACK_COPYRIGHT_HOLDER\' | translate}}</label>\n' +
    '                    <input name="data.copyright_holder" ng-model="data.copyright_holder"\n' +
    '                           ng-disabled="transaction.busy()"\n' +
    '                           step="any" type="text" tabindex="0"\n' +
    '                           required/>\n' +
    '\n' +
    '                    <div ng-messages="errorsWithHint(form.data.copyright_holder)">\n' +
    '                        <div ng-message="hint">\n' +
    '                            {{::\'FEEDBACK_COPYRIGHT_HOLDER_HINT\' | translate}}\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </md-input-container>\n' +
    '\n' +
    '                <md-input-container class="md-block flex">\n' +
    '                    <label>{{::\'FEEDBACK_ORIGINAL_LOCATION\'|translate}}</label>\n' +
    '                    <input type="text" ng-model="data.original_loc"\n' +
    '                           ng-disabled="transaction.busy()"/>\n' +
    '                </md-input-container>\n' +
    '\n' +
    '                <md-input-container class="md-block">\n' +
    '                    <label>{{::\'FEEDBACK_DESCRIBE_COPYRIGHTED\' | translate}}</label>\n' +
    '                    <input name="data.copyrighted_work" ng-model="data.copyrighted_work"\n' +
    '                           ng-disabled="transaction.busy()"\n' +
    '                           step="any" type="text" tabindex="0"\n' +
    '                           required/>\n' +
    '\n' +
    '                    <div ng-messages="errorsWithHint(form.data.copyrighted_work)">\n' +
    '                        <div ng-message="hint">\n' +
    '                            {{::\'FEEDBACK_DESCRIBE_COPYRIGHTED_HINT\'| translate}}\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </md-input-container>\n' +
    '\n' +
    '                <md-input-container class="md-block">\n' +
    '                    <label>{{::\'FEEDBACK_UNAUTHORIZE_LOCATION\' | translate}}</label>\n' +
    '                        <textarea name="data.unauth_loc" ng-model="data.unauth_loc"\n' +
    '                                  ng-disabled="transaction.busy()"\n' +
    '                                  step="any" type="text" tabindex="0"\n' +
    '                                  required></textarea>\n' +
    '\n' +
    '                    <div ng-messages="errorsWithHint(form.data.unauth_loc)">\n' +
    '                        <div ng-message="hint">\n' +
    '                            {{::\'FEEDBACK_UNAUTHORIZE_LOCATION_HINT\' | translate}}\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </md-input-container>\n' +
    '\n' +
    '                <div class="bm16 layout-row">\n' +
    '                    <md-checkbox ng-model="data.copyright_conc" class="lm0 bm0 flex-fixed"\n' +
    '                                 aria-label=\'FEEDBACK_COPYRIGHT_CONC\' style="min-width: 24px; margin-top: -2px">\n' +
    '                    </md-checkbox>\n' +
    '                    <p class="m0 text-small text-grey line-height-string">\n' +
    '                        {{::\'FEEDBACK_COPYRIGHT_CONC\'|translate}}\n' +
    '                    </p>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="bm16 layout-row">\n' +
    '                    <md-checkbox ng-model="data.request_conc" class="lm0 bm0 flex-fixed"\n' +
    '                                 aria-label="FEEDBACK_REGUEST_CONC" style="min-width: 24px; margin-top: -2px">\n' +
    '                    </md-checkbox>\n' +
    '                    <p class="m0 text-small text-grey line-height-string">\n' +
    '                        {{::\'FEEDBACK_REGUEST_CONC\'|translate}}\n' +
    '                    </p>\n' +
    '                </div>\n' +
    '\n' +
    '                <md-input-container class="md-block">\n' +
    '                    <label>{{::\'FEEDBACK_SIGNATURE\' | translate}}</label>\n' +
    '                    <input name="data.signature" ng-model="data.signature" ng-disabled="transaction.busy()"\n' +
    '                           step="any" type="text" tabindex="0"\n' +
    '                           required/>\n' +
    '\n' +
    '                    <div ng-messages="errorsWithHint(form.data.signature)">\n' +
    '                        <div ng-message="hint">{{::\'FEEDBACK_SIGNATURE_HINT\' | translate}}</div>\n' +
    '                    </div>\n' +
    '                </md-input-container>\n' +
    '            </div>\n' +
    '\n' +
    '            <pip-picture-list-edit class="bm8" ng-show="showPictures"\n' +
    '                                   pip-picture-ids="data.pic_ids" pip-created="pictures = $event.sender"\n' +
    '                                   ng-disabled="transaction.busy()">\n' +
    '            </pip-picture-list-edit>\n' +
    '\n' +
    '            <pip-document-list-edit ng-show="showDocuments"\n' +
    '                                    pip-documents="data.docs" pip-created="docs = $event.sender"\n' +
    '                                    ng-disabled="transaction.busy()">\n' +
    '            </pip-document-list-edit>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();
