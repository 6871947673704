/**
 * @file Registration of pictures WebUI controls
 * @copyright Digital Living Software Corp. 2014-2015
 */

/* global angular */

(function () {
    'use strict';

    angular.module('pipPictures', [        
        'pipAddImage',
        'pipAvatar',
        'pipAvatarEdit',
        'pipPicture',
        'pipPictureEdit',
        'pipCollage',
        'pipPictureListEdit',        
        'pipCameraDialog',        
        'pipPictureUrlDialog'
    ]);
    
})();


