(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('post_signup/post_signup.html',
    '<!--\n' +
    '@file Post-signup page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="pip-card-container pip-outer-scroll pip-entry">\n' +
    '    <pip-card width="400">\n' +
    '        <pip-post-signup-panel\n' +
    '                pip-data="data"\n' +
    '                pip-created="$panel = $control"\n' +
    '                pip-party="$party">\n' +
    '\n' +
    '        </pip-post-signup-panel>\n' +
    '        <div class="pip-footer">\n' +
    '            <md-button ng-hide="transaction.busy()" class="md-accent"\n' +
    '                       ng-click="onPostSignupSubmit()" aria-label="CONTINUE">\n' +
    '                {{::\'CONTINUE\' | translate}}\n' +
    '            </md-button>\n' +
    '\n' +
    '            <md-button ng-show="transaction.busy()" class="md-warn"\n' +
    '                       ng-click="transaction.abort()" aria-label="ABORT">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </pip-card>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('post_signup/post_signup_dialog.html',
    '<!--\n' +
    '@file Post signup dialog\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-dialog class="pip-entry lp0 rp0">\n' +
    '    <md-dialog-content>\n' +
    '        <pip-post-signup-panel\n' +
    '                pip-data="data"\n' +
    '                pip-created="$panel = $control"\n' +
    '                pip-party="$party">\n' +
    '\n' +
    '        </pip-post-signup-panel>\n' +
    '    </md-dialog-content>\n' +
    '    <md-dialog-actions class="layout-row layout-align-end-center">\n' +
    '        <md-button ng-hide="transaction.busy()" class="md-accent"\n' +
    '                   ng-click="onPostSignupSubmit()" aria-label="CONTINUE">\n' +
    '            {{::\'CONTINUE\' | translate}}\n' +
    '        </md-button>\n' +
    '\n' +
    '        <md-button ng-show="transaction.busy()" class="md-warn"\n' +
    '                   ng-click="transaction.abort()" aria-label="ABORT">\n' +
    '            {{::\'CANCEL\' | translate}}\n' +
    '        </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('post_signup/post_signup_panel.html',
    '<div class="pip-body">\n' +
    '    <div class="pip-content">\n' +
    '        <md-progress-linear ng-show="transaction.busy() && !hideObject.progress" md-mode="indeterminate" class="pip-progress-top">\n' +
    '        </md-progress-linear>\n' +
    '\n' +
    '        <h2 class="text-overflow" ng-if="!hideObject.title">{{\'POST_SIGNUP_TITLE\' | translate}}</h2>\n' +
    '\n' +
    '        <p class="bm0 line-height-string" ng-if="!hideObject.successTitle">\n' +
    '            {{\'POST_SIGNUP_TEXT_1\' | translate}}\n' +
    '        </p>\n' +
    '\n' +
    '        <p class="line-height-string m0" ng-if="!hideObject.subTitle">\n' +
    '            {{\'POST_SIGNUP_TEXT_2\' | translate}}\n' +
    '        </p>\n' +
    '\n' +
    '        <form name="form" novalidate>\n' +
    '            <div ng-messages="form.$serverError" class="text-error bm8"  md-auto-hide="false">\n' +
    '                <div ng-message="ERROR_1000">{{::\'ERROR_1000\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1110">{{::\'ERROR_1110\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1111">{{::\'ERROR_1111\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1112">{{::\'ERROR_1112\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1002">{{::\'ERROR_1002\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_-1">{{::\'ERROR_SERVER\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_UNKNOWN">\n' +
    '                    {{ form.$serverError.ERROR_UNKNOWN | translate }}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="pip-ref-item">\n' +
    '                <pip-avatar-edit ng-disabled="transaction.busy()"\n' +
    '                                 pip-reset="false" pip-party-id="data.id"\n' +
    '                                 pip-created="onPictureCreated($event)"\n' +
    '                                 pip-changed="onPictureChanged($control, $event)"\n' +
    '                                 class="rm16 flex-fixed">\n' +
    '                </pip-avatar-edit>\n' +
    '\n' +
    '                <div class="pip-content">\n' +
    '                    <p class="pip-title">{{data.name}}</p>\n' +
    '                    <p class="pip-subtitle">{{data.email}}</p>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '\n' +
    '            <md-input-container class="pip-no-hint bp4">\n' +
    '                <label>{{\'HINT_ABOUT\' | translate}}</label>\n' +
    '                        <textarea ng-model="data.about"  ng-initial ng-disabled="transaction.busy()" pip-clear-errors>\n' +
    '                        </textarea>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <div class="tm2">\n' +
    '                <p class="text-caption bm0">{{\'GENDER\' | translate}}</p>\n' +
    '                <md-select class="w-stretch tm0 tp0 bp8" ng-disabled="transaction.busy()"\n' +
    '                           ng-model="data.gender" label="{{\'GENDER\' | translate}}"\n' +
    '                           ng-change="onStatusChange(data)" pip-clear-errors>\n' +
    '                    <md-option ng-value="opt.id" ng-repeat="opt in genders track by opt.id">\n' +
    '                        {{ opt.name }}\n' +
    '                    </md-option>\n' +
    '                </md-select>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="tm2">\n' +
    '                <p class="text-caption bm0">{{::\'BIRTHDAY\' | translate}}</p>\n' +
    '                <pip-date ng-disabled="transaction.busy()"\n' +
    '                          ng-model="data.birthday"\n' +
    '                          pip-time-mode="past"\n' +
    '                          pip-clear-errors time-mode="past">\n' +
    '                </pip-date>\n' +
    '            </div>\n' +
    '           <md-input-container>\n' +
    '               <label>{{::\'LANGUAGE\' | translate}}</label>\n' +
    '               <md-select class="w-stretch tm0 tp0  bp16" ng-disabled="transaction.busy()"\n' +
    '                          ng-model="data.language"\n' +
    '                          ng-change="onStatusChange(data)" pip-clear-errors>\n' +
    '                   <md-option ng-value="opt.id" ng-repeat="opt in languages track by opt.id">\n' +
    '                       {{ opt.name }}\n' +
    '                   </md-option>\n' +
    '               </md-select>\n' +
    '           </md-input-container>\n' +
    '\n' +
    '\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('recover_password/recover_password.html',
    '<!--\n' +
    '@file Password recovery page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="pip-card-container pip-outer-scroll pip-entry">\n' +
    '    <pip-card width="400">\n' +
    '        <pip-recover-password-panel\n' +
    '                pip-data="data"\n' +
    '                pip-created="$panel = $control">\n' +
    '\n' +
    '        </pip-recover-password-panel>\n' +
    '        <div class="pip-footer">\n' +
    '            <md-button ng-hide="transaction.busy()" ng-click="goBack()" class="rm8" aria-label="CANCEL">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '\n' +
    '            <md-button ng-hide="transaction.busy()" class="md-accent" ng-click="onRecover()"\n' +
    '                       aria-label="RECOVER_PWD_RECOVER"\n' +
    '                       ng-disabled="(form.$pristine && !data.email) || data.serverUrl.length == 0 || data.email.length == 0">\n' +
    '                {{::\'RECOVER_PWD_RECOVER\' | translate}}\n' +
    '            </md-button>\n' +
    '\n' +
    '            <md-button ng-show="transaction.busy()" class="md-warn" ng-click="transaction.abort()" aria-label="ABORT">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </pip-card>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('recover_password/recover_password_dialog.html',
    '<!--\n' +
    '@file Recover password dialog\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-dialog class="pip-entry lp0 rp0">\n' +
    '    <md-dialog-content>\n' +
    '        <pip-recover-password-panel\n' +
    '                pip-data="data"\n' +
    '                pip-created="$panel = $control"\n' +
    '                pip-goto-reset="pipGotoReset">\n' +
    '\n' +
    '        </pip-recover-password-panel>\n' +
    '    </md-dialog-content>\n' +
    '\n' +
    '    <md-dialog-actions class="layout-row layout-align-end-center">\n' +
    '        <md-button ng-hide="transaction.busy()" ng-click="goBack()" class="rm8" aria-label="CANCEL">\n' +
    '            {{::\'CANCEL\' | translate}}\n' +
    '        </md-button>\n' +
    '\n' +
    '        <md-button ng-hide="transaction.busy()" class="md-accent" ng-click="onRecover()"\n' +
    '                   aria-label="RECOVER_PWD_RECOVER"\n' +
    '                   ng-disabled="(form.$pristine && !data.email) || data.email== undefined ||\n' +
    '                           || data.serverUrl.length == 0 || data.email.length == 0">\n' +
    '            {{::\'RECOVER_PWD_RECOVER\' | translate}}\n' +
    '        </md-button>\n' +
    '\n' +
    '        <md-button ng-show="transaction.busy()" class="md-warn" ng-click="transaction.abort()" aria-label="ABORT">\n' +
    '            {{::\'CANCEL\' | translate}}\n' +
    '        </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('recover_password/recover_password_panel.html',
    '<div class="pip-body">\n' +
    '    <div class="pip-content">\n' +
    '        <md-progress-linear ng-show="transaction.busy() && !hideObject.progress" md-mode="indeterminate" class="pip-progress-top">\n' +
    '        </md-progress-linear>\n' +
    '\n' +
    '        <h2 ng-if="!hideObject.title">{{\'RECOVER_PWD_TITLE\' | translate}}</h2>\n' +
    '\n' +
    '        <p class="text-primary tm0 bm16" ng-if="!hideObject.subTitle1">{{\'RECOVER_PWD_TEXT_1\' | translate}} </p>\n' +
    '\n' +
    '        <p class="text-primary tm0 bm16" ng-if="!hideObject.subTitle2">{{\'RECOVER_PWD_TEXT_2\' | translate}}</p>\n' +
    '\n' +
    '        <form name="form" novalidate>\n' +
    '            <div ng-messages="form.$serverError" class="text-error bm8"  md-auto-hide="false">\n' +
    '                <div ng-message="ERROR_1000">{{::\'ERROR_1000\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1110">{{::\'ERROR_1110\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1111">{{::\'ERROR_1111\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1112">{{::\'ERROR_1112\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_-1">{{::\'ERROR_SERVER\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_UNKNOWN">\n' +
    '                    {{ form.$serverError.ERROR_UNKNOWN | translate }}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <a ng-hide="showServerUrl || fixedServerUrl || hideObject.server" ng-click="showServerUrl = true" href="">\n' +
    '                {{\'ENTRY_CHANGE_SERVER\' | translate}}\n' +
    '            </a>\n' +
    '\n' +
    '            <div ng-show="showServerUrl && !hideObject.server">\n' +
    '                <md-autocomplete\n' +
    '                        ng-initial autofocus tabindex="1"\n' +
    '                        class="pip-combobox w-stretch bm8"\n' +
    '                        name="server"\n' +
    '                        ng-enabled="!transaction.busy()"\n' +
    '                        placeholder="{{::\'ENTRY_SERVER_URL\' | translate}}"\n' +
    '                        md-no-cache="true"\n' +
    '                        md-selected-item="data.serverUrl"\n' +
    '                        md-search-text="selected.searchURLs"\n' +
    '                        md-items="item in getMatches()"\n' +
    '                        md-item-text="item"\n' +
    '                        md-selected-item-change="onServerUrlChanged()"\n' +
    '                        md-delay="200"\n' +
    '                        ng-model="data.serverUrl"\n' +
    '                        pip-clear-errors>\n' +
    '                    <span md-highlight-text="selected.searchURLs">{{item}}</span>\n' +
    '                </md-autocomplete>\n' +
    '            </div>\n' +
    '            <md-input-container class="pip-no-hint" style="padding-bottom: 4px!important;">\n' +
    '                <label>{{::\'EMAIL\' | translate}}</label>\n' +
    '                <input name="email" type="email"\n' +
    '                       ng-model="data.email"\n' +
    '                       pip-email-unique="data.email"\n' +
    '                       required step="any" pip-clear-errors\n' +
    '                       ng-disabled="transaction.busy()" tabindex="2"/>\n' +
    '\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.email).hint && !hideObject.hint">\n' +
    '                    {{::\'HINT_EMAIL\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.email)"\n' +
    '                     class="md-input-error"  md-auto-hide="false">\n' +
    '                    <div ng-message="required">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="email">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="emailUnique">{{::\'ERROR_1104\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1100">{{::\'ERROR_1100\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1106">{{::\'ERROR_1106\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '        </form>\n' +
    '\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('reset_password/reset_password.html',
    '<!--\n' +
    '@file Password reset page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="pip-card-container pip-outer-scroll pip-entry">\n' +
    '    <pip-card width="400">\n' +
    '\n' +
    '        <pip-reset-password-panel\n' +
    '                pip-data="data"\n' +
    '                pip-created="$panel = $control">\n' +
    '\n' +
    '        </pip-reset-password-panel>\n' +
    '        <div class="pip-footer">\n' +
    '            <md-button ng-hide="transaction.busy()" ng-click="goBack()" class="rm8" aria-label="CANCEL">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '            <md-button ng-hide="transaction.busy()" ng-click="onReset()" aria-label="ENTRY_SET_PASSWORD"\n' +
    '                       ng-disabled="(form.$pristine && !data.email) || data.serverUrl.length == 0 ||\n' +
    '                       data.email.length == 0 || data.code.length == 0 || data.password.length < 6"\n' +
    '                       class="md-accent" type="submit">\n' +
    '                {{::\'ENTRY_SET_PASSWORD\' | translate}}\n' +
    '            </md-button>\n' +
    '            <md-button class="md-warn" ng-show="transaction.busy()" ng-click="transaction.abort()" aria-label="ABORT">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </pip-card>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('reset_password/reset_password_dialog.html',
    '<!--\n' +
    '@file Reset password\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-dialog class="pip-entry  lp0 rp0">\n' +
    '    <md-dialog-content>\n' +
    '        <pip-reset-password-panel\n' +
    '                pip-data="data"\n' +
    '                pip-created="$panel = $control">\n' +
    '\n' +
    '        </pip-reset-password-panel>\n' +
    '    </md-dialog-content>\n' +
    '\n' +
    '    <md-dialog-actions class="layout-row layout-align-end-center">\n' +
    '        <md-button ng-hide="transaction.busy()" ng-click="goBack()" class="rm8" aria-label="CANCEL">\n' +
    '            {{::\'CANCEL\' | translate}}\n' +
    '        </md-button>\n' +
    '        <md-button ng-hide="transaction.busy()" ng-click="onReset()" aria-label="ENTRY_SET_PASSWORD"\n' +
    '                   ng-disabled="(form.$pristine && !data.email) || data.serverUrl.length == 0 ||\n' +
    '                       data.email.length == 0 || data.code.length == 0 || data.password.length < 6"\n' +
    '                   class="md-accent" type="submit">\n' +
    '            {{::\'ENTRY_SET_PASSWORD\' | translate}}\n' +
    '        </md-button>\n' +
    '        <md-button class="md-warn" ng-show="transaction.busy()" ng-click="transaction.abort()" aria-label="ABORT">\n' +
    '            {{::\'CANCEL\' | translate}}\n' +
    '        </md-button>\n' +
    '    </md-dialog-actions>\n' +
    '</md-dialog>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('reset_password/reset_password_panel.html',
    '<div class="pip-body">\n' +
    '    <div class="pip-content">\n' +
    '        <md-progress-linear ng-show="transaction.busy()  && showServerError  && !hideObject.progress" md-mode="indeterminate"\n' +
    '                            class="pip-progress-top">\n' +
    '        </md-progress-linear>\n' +
    ' \n' +
    '        <h2 ng-if="!hideObject.title">{{::\'RESET_PWD_PASSWORD\' | translate}}</h2>\n' +
    '\n' +
    '        <p class="title-padding bm16" ng-if="!hideObject.subTitle">\n' +
    '            {{::\'RESET_PWD_TEXT\' | translate}}\n' +
    '        </p>\n' +
    '\n' +
    '        <form name="form" novalidate>\n' +
    '            <div ng-messages="form.$serverError" class="text-error bm8"  md-auto-hide="false">\n' +
    '                <div ng-message="ERROR_1000">{{::\'ERROR_1000\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1110">{{::\'ERROR_1110\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1111">{{::\'ERROR_1111\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1112">{{::\'ERROR_1112\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_-1">{{::\'ERROR_SERVER\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_UNKNOWN">\n' +
    '                    {{ form.$serverError.ERROR_UNKNOWN | translate }}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <a ng-hide="showServerUrl || fixedServerUrl || hideObject.server" ng-click="showServerUrl = true" href="">\n' +
    '                {{::\'ENTRY_CHANGE_SERVER\' | translate}}\n' +
    '            </a>\n' +
    '\n' +
    '            <div ng-show="showServerUrl && !hideObject.server">\n' +
    '                <md-autocomplete\n' +
    '                        ng-initial autofocus tabindex="1"\n' +
    '                        class="pip-combobox w-stretch bm8"\n' +
    '                        name="server"\n' +
    '                        ng-enabled="!transaction.busy()"\n' +
    '                        placeholder="{{::\'ENTRY_SERVER_URL\' | translate}}"\n' +
    '                        md-no-cache="true"\n' +
    '                        md-selected-item="data.serverUrl"\n' +
    '                        md-search-text="selected.searchURLs"\n' +
    '                        md-items="item in getMatches()"\n' +
    '                        md-item-text="item"\n' +
    '                        md-selected-item-change="onServerUrlChanged()"\n' +
    '                        md-delay="200"\n' +
    '                        ng-model="data.serverUrl"\n' +
    '                        pip-clear-errors>\n' +
    '                    <span md-highlight-text="selected.searchURLs">{{item}}</span>\n' +
    '                </md-autocomplete>\n' +
    '            </div>\n' +
    '\n' +
    '            <md-input-container class="pip-no-hint" style="padding-bottom: 4px!important;">\n' +
    '                <label>{{::\'EMAIL\' | translate}}</label>\n' +
    '                <input name="email" type="email" ng-model="data.email" required step="any" pip-clear-errors\n' +
    '                       ng-disabled="transaction.busy()" tabindex="2" pip-email-unique="data.email"/>\n' +
    '\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.email).hint && !hideObject.hint">\n' +
    '                    {{::\'HINT_EMAIL\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.email)" class="md-input-error"  md-auto-hide="false">\n' +
    '\n' +
    '                    <div ng-message="required">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="email">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="emailUnique">{{::\'ERROR_1104\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1100">{{::\'ERROR_1100\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1106">{{::\'ERROR_1106\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <md-input-container class="pip-no-hint">\n' +
    '                <label>{{::\'ENTRY_RESET_CODE\' | translate}}</label>\n' +
    '                <input name="code" ng-disabled="transaction.busy()"\n' +
    '                       ng-model="data.code" required tabindex="3" pip-clear-errors/>\n' +
    '\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.code).hint && !hideObject.hint">\n' +
    '                    {{::\'ENTRY_RESET_CODE\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.code)" class="md-input-error"  md-auto-hide="false">\n' +
    '                    <div ng-message="required">{{::\'ERROR_CODE_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="ERROR_1108">{{::\'ERROR_1108\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1109">{{::\'ERROR_1109\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <md-input-container class="pip-no-hint" style="padding-bottom: 4px!important;">\n' +
    '                <label>{{::\'PASSWORD\' | translate}}</label>\n' +
    '                <input name="password" ng-disabled="transaction.busy()" pip-clear-errors\n' +
    '                       type="password" tabindex="4" ng-model="data.password"\n' +
    '                       required minlength="6"/>\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.password).hint && !hideObject.hint">\n' +
    '                    {{::\'HINT_PASSWORD\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.password)"\n' +
    '                     class="md-input-error"  md-auto-hide="false">\n' +
    '                    <div ng-message="required">{{::\'MINLENGTH_PASSWORD\' | translate}}</div>\n' +
    '                    <div ng-message="minlength">{{::\'MINLENGTH_PASSWORD\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1102">{{::\'ERROR_1102\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1103">{{::\'ERROR_1103\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1105">{{::\'ERROR_1105\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('signin/signin.html',
    '<!--\n' +
    '@file Signin page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="pip-card-container pip-outer-scroll pip-entry">\n' +
    '    <pip-card width="400">\n' +
    '        <pip-signin-panel pipfixedServerUrl="fixedServerUrl" >\n' +
    '        </pip-signin-panel>\n' +
    '    </pip-card>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('signin/signin_dialog.html',
    '<!--\n' +
    '@file Signin dialog\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-dialog class="pip-entry">\n' +
    '    <md-dialog-content>\n' +
    '        <pip-signin-panel pip-goto-signup-dialog="pipGotoSignupDialog"\n' +
    '                          pip-goto-recover-password-dialog="pipGotoRecoverPasswordDialog">\n' +
    '        </pip-signin-panel>\n' +
    '    </md-dialog-content>\n' +
    '</md-dialog>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('signin/signin_panel.html',
    '<div class="pip-body">\n' +
    '    <div class="pip-content">\n' +
    '        <md-progress-linear ng-show="transaction.busy() && !hideObject.progress" md-mode="indeterminate" class="pip-progress-top">\n' +
    '        </md-progress-linear>\n' +
    '\n' +
    '        <h2 pip-translate="SIGNIN_TITLE" ng-if="!hideObject.title"></h2>\n' +
    '\n' +
    '        <form name="form" novalidate>\n' +
    '            <div ng-messages="form.$serverError" class="text-error bm8 color-error"  md-auto-hide="false">\n' +
    '                <div ng-message="ERROR_1000">{{::\'ERROR_1000\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1110">{{::\'ERROR_1110\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1111">{{::\'ERROR_1111\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1112">{{::\'ERROR_1112\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_-1">{{::\'ERROR_SERVER\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_UNKNOWN">\n' +
    '                    {{ form.$serverError.ERROR_UNKNOWN | translate }}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <a ng-hide="showServerUrl || fixedServerUrl || hideObject.server"\n' +
    '               ng-click="showServerUrl = true" href=""\n' +
    '               id="link-server-url"\n' +
    '               pip-test="link-server-url">\n' +
    '                {{::\'ENTRY_CHANGE_SERVER\' | translate}}\n' +
    '            </a>\n' +
    '\n' +
    '            <div ng-show="showServerUrl && !hideObject.server">\n' +
    '                <md-autocomplete\n' +
    '                        ng-initial autofocus tabindex="1"\n' +
    '                        class="pip-combobox w-stretch bm8"\n' +
    '                        name="server"\n' +
    '                        placeholder="{{::\'ENTRY_SERVER_URL\' | translate}}"\n' +
    '                        md-no-cache="true"\n' +
    '                        md-selected-item="data.serverUrl"\n' +
    '                        md-search-text="selected.searchURLs"\n' +
    '                        md-items="item in getMatches()"\n' +
    '                        md-item-text="item"\n' +
    '                        md-selected-item-change="onServerUrlChanged()"\n' +
    '                        md-delay="200"\n' +
    '                        ng-model="data.serverUrl"\n' +
    '                        ng-disabled="transaction.busy()"\n' +
    '                        pip-clear-errors\n' +
    '                        pip-test="autocomplete-server">\n' +
    '                    <span md-highlight-text="selected.searchURLs">{{item}}</span>\n' +
    '                </md-autocomplete>\n' +
    '            </div>\n' +
    '\n' +
    '            <md-input-container class="display  bp4">\n' +
    '                <label>{{::\'EMAIL\' | translate}}</label>\n' +
    '                <input name="email" type="email" ng-model="data.email" required step="any"\n' +
    '                       ng-keypress="onEnter($event)"\n' +
    '                       pip-clear-errors\n' +
    '                       ng-disabled="transaction.busy()" tabindex="2"\n' +
    '                       pip-test="input-email"/>\n' +
    '\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.email).hint && !hideObject.hint">\n' +
    '                    {{::\'HINT_EMAIL\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.email)" md-auto-hide="false">\n' +
    '                    <div ng-message="required">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="email">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="ERROR_1100">{{::\'ERROR_1100\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1106">{{::\'ERROR_1106\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1114">{{::\'ERROR_1114\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <md-input-container class="display bp4">\n' +
    '                <label>{{::\'PASSWORD\' | translate}}</label>\n' +
    '                <input name="password" ng-disabled="transaction.busy()" pip-clear-errors\n' +
    '                       type="password" tabindex="3" ng-model="data.password"\n' +
    '                       ng-keypress="onEnter($event)"\n' +
    '                       required minlength="6"\n' +
    '                       pip-test="input-password"/>\n' +
    '\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.password).hint && !hideObject.hint">\n' +
    '                    {{::\'SIGNIN_HINT_PASSWORD\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.password)"  md-auto-hide="false">\n' +
    '                    <div ng-message="required">{{::\'SIGNIN_HINT_PASSWORD\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1102">{{::\'ERROR_1102\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1107">{{::\'ERROR_1107\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '            <a href="" class="display bm16"\n' +
    '               ng-if="!hideObject.forgotPassword"\n' +
    '               ng-click="gotoRecoverPassword()"\n' +
    '               tabindex="4">\n' +
    '                {{::\'SIGNIN_FORGOT_PASSWORD\' | translate}}\n' +
    '            </a>\n' +
    '\n' +
    '            <md-checkbox ng-disabled="transaction.busy()" \n' +
    '                         ng-if="!hideObject.forgotPassword"\n' +
    '                         md-no-ink class="lm0"\n' +
    '                         aria-label="{{\'SIGNIN_REMEMBER\' | translate}}" tabindex="5"\n' +
    '                         ng-model="data.remember"\n' +
    '                         pip-test-checkbox="remember">\n' +
    '                <label class="label-check">{{::\'SIGNIN_REMEMBER\' | translate}}</label>\n' +
    '            </md-checkbox>\n' +
    '\n' +
    '            <div style="height: 36px; overflow: hidden;">\n' +
    '                <md-button ng-if="!transaction.busy()" ng-click="onSignin()" aria-label="SIGNIN"\n' +
    '                           class="md-raised md-accent w-stretch m0" tabindex="6"\n' +
    '                           ng-disabled="(data.email == undefined) || data.email.length == 0 || data.serverUrl == \'\' ||\n' +
    '                                   data.serverUrl == undefined || data.serverUrl.length == 0 || (data.password == undefined)"\n' +
    '                           pip-test="button-signin">\n' +
    '                    {{::\'SIGNIN_TITLE\' | translate}}\n' +
    '                </md-button>\n' +
    '                <md-button ng-if="transaction.busy()" ng-click="transaction.abort()" class="md-raised md-warn m0 w-stretch"\n' +
    '                           tabindex="5" aria-label="ABORT"\n' +
    '                           pip-test="button-cancel">\n' +
    '                    {{::\'CANCEL\' | translate}}\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '            <div class="tm24 layout-row" ng-if="!adminOnly && $mdMedia(\'gt-xs\') && !hideObject.signup">\n' +
    '                <p class="m0 text-small"> <!--  <p class="a-question-text">  -->\n' +
    '                    {{::\'SIGNIN_NOT_MEMBER\' | translate}}\n' +
    '                    <a href=""\n' +
    '                       ng-click="gotoSignup()"\n' +
    '                       tabindex="6">\n' +
    '                        {{::\'SIGNIN_SIGNUP_HERE\' | translate}}\n' +
    '                    </a>\n' +
    '                </p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="tm24 divider-top text-signup" \n' +
    '                 ng-if="!adminOnly && $mdMedia(\'xs\') && !hideObject.signup">\n' +
    '                <div class="h48 layout-row layout-align-center-end">\n' +
    '                    <p class="m0 text-small">{{::\'SIGNIN_NOT_MEMBER\' | translate}}</p>\n' +
    '                </div>\n' +
    '                <div class="h48 layout-row layout-align-center-start">\n' +
    '                    <a class="text-small" ng-click="gotoSignup()" href="" tabindex="6">\n' +
    '                        {{::\'SIGNIN_SIGNUP_HERE\' | translate}}\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('signup/signup.html',
    '<!--\n' +
    '@file Signup page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="pip-card-container pip-outer-scroll pip-entry">\n' +
    '    <pip-card width="400">\n' +
    '        <pip-signup-panel>\n' +
    '        </pip-signup-panel>\n' +
    '    </pip-card>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('signup/signup_dialog.html',
    '<!--\n' +
    '@file Signup page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-dialog class="pip-entry">\n' +
    '    <md-dialog-content>\n' +
    '        <pip-signup-panel pip-goto-signin-dialog="pipGotoSigninDialog"\n' +
    '                          pip-post-signup="pipPostSignup"></pip-signup-panel>\n' +
    '    </md-dialog-content>\n' +
    '</md-dialog>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('signup/signup_panel.html',
    '<div class="pip-body ">\n' +
    '    <div class="pip-content">\n' +
    '        <md-progress-linear ng-show="transaction.busy() && !hideObject.progress" md-mode="indeterminate" class="pip-progress-top">\n' +
    '        </md-progress-linear>\n' +
    '\n' +
    '        <h2 pip-translate="SIGNUP_TITLE" ng-if="!hideObject.title"></h2>\n' +
    '\n' +
    '        <form name="form" novalidate autocomplete="off">\n' +
    '            <input type="email" style="display:none">\n' +
    '            <input type="password" style="display:none">\n' +
    '\n' +
    '            <div ng-messages="form.$serverError" class="text-error bm8"  md-auto-hide="false">\n' +
    '                <div ng-message="ERROR_1000">{{::\'ERROR_1000\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1110">{{::\'ERROR_1110\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1111">{{::\'ERROR_1111\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_1112">{{::\'ERROR_1112\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_-1">{{::\'ERROR_SERVER\' | translate}}</div>\n' +
    '                <div ng-message="ERROR_UNKNOWN">\n' +
    '                    {{ form.$serverError.ERROR_UNKNOWN | translate }}\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <a ng-hide="showServerUrl || fixedServerUrl || hideObject.server" ng-click="showServerUrl = true" href="">\n' +
    '                {{::\'ENTRY_CHANGE_SERVER\' | translate}}\n' +
    '            </a>\n' +
    '\n' +
    '            <div ng-show="showServerUrl && !hideObject.server">\n' +
    '                <md-autocomplete\n' +
    '                        ng-initial autofocus tabindex="1"\n' +
    '                        class="pip-combobox w-stretch bm8"\n' +
    '                        name="server"\n' +
    '                        ng-enabled="!transaction.busy()"\n' +
    '                        placeholder="{{::\'ENTRY_SERVER_URL\' | translate}}"\n' +
    '                        md-no-cache="true"\n' +
    '                        md-selected-item="data.serverUrl"\n' +
    '                        md-search-text="selected.searchURLs"\n' +
    '                        md-items="item in getMatches()"\n' +
    '                        md-item-text="item"\n' +
    '                        md-selected-item-change="onServerUrlChanged()"\n' +
    '                        md-delay="200"\n' +
    '                        ng-model="data.serverUrl"\n' +
    '                        ng-disabled="transaction.busy()"\n' +
    '                        pip-clear-errors>\n' +
    '                    <span md-highlight-text="selected.searchURLs">{{item}}</span>\n' +
    '                </md-autocomplete>\n' +
    '            </div>\n' +
    '\n' +
    '            <md-input-container class="display bp4">\n' +
    '                <label>{{::\'FULLNAME\' | translate}}</label>\n' +
    '                <input name="signupFullName"\n' +
    '                       ng-disabled="transaction.busy()" autocomplete="off"\n' +
    '                       ng-model="data.name" ng-init="data.name = \'\'"\n' +
    '                       required tabindex="2" pip-clear-errors\n' +
    '                       ng-keypress="onEnter($event)">\n' +
    '\n' +
    '                <div class="hint text-overflow w-stretch"\n' +
    '                     ng-if="touchedErrorsWithHint(form, form.signupFullName).hint && !hideObject.hint">\n' +
    '                    {{::\'HINT_FULLNAME\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.signupFullName)"  md-auto-hide="false">\n' +
    '                    <div ng-message="required">\n' +
    '                        {{::\'HINT_FULLNAME\' | translate}} {{::\'ERROR_FULLNAME_INVALID\' | translate }}\n' +
    '                    </div>\n' +
    '                    <div ng-message="ERROR_1101">{{::\'ERROR_1101\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <md-input-container class="display bp4">\n' +
    '                <label>{{::\'EMAIL\' | translate}}</label>\n' +
    '                <input name="userEmail" ng-disabled="transaction.busy()" pip-clear-errors\n' +
    '                       type="email" tabindex="3" ng-model="data.email"\n' +
    '                       required\n' +
    '                       pip-email-unique="data.email"\n' +
    '                       ng-keypress="onEnter($event)"\n' +
    '                       pip-test="input-password"/>\n' +
    '\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.userEmail).hint && !hideObject.hint">\n' +
    '                    {{::\'HINT_EMAIL\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.userEmail)" md-auto-hide="false"  md-auto-hide="false">\n' +
    '                    <div ng-message="required">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="email">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                    <div ng-message="emailUnique">{{::\'ERROR_1104\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1100">{{::\'ERROR_1100\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1106">{{::\'ERROR_1106\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1104">{{::\'ERROR_1104\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1300">{{::\'ERROR_1300\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1305">{{::\'ERROR_1305\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1301">{{::\'ERROR_1301\' | translate}}</div>\n' +
    '                    <div ng-message="ERROR_1114">{{::\'ERROR_1114\' | translate}}</div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <md-input-container class="display bp4">\n' +
    '                <label>{{::\'PASSWORD_SET\' | translate}}</label>\n' +
    '                <input name="password" ng-disabled="transaction.busy()" pip-clear-errors\n' +
    '                       type="password" tabindex="4" ng-model="data.password"\n' +
    '                       required minlength="6"\n' +
    '                       ng-keypress="onEnter($event)"\n' +
    '                       pip-test="input-password"/>\n' +
    '\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.password).hint && !hideObject.hint">\n' +
    '                    {{::\'HINT_PASSWORD\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.password)"  md-auto-hide="false">\n' +
    '                    <div ng-message="required">\n' +
    '                        {{::\'HINT_PASSWORD\' | translate}}\n' +
    '                    </div>\n' +
    '                    <div ng-message="minlength">\n' +
    '                        {{::\'HINT_PASSWORD\' | translate}}\n' +
    '                    </div>\n' +
    '                    <div ng-message="ERROR_1102" ng-if="!form.password.$pristine">\n' +
    '                        {{::\'ERROR_1102\' | translate}}\n' +
    '                    </div>\n' +
    '                    <div ng-message="ERROR_1107" ng-if="!form.password.$pristine">\n' +
    '                        {{::\'ERROR_1107\' | translate}}\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <md-input-container class="display bp4" ng-if="!hideObject.passwordConfirm">\n' +
    '                <label>{{::\'PASSWORD_CONFIRM\' | translate}}</label>\n' +
    '                <input name="passwordConfirm"\n' +
    '                       type="password" tabindex="4"\n' +
    '                       required minlength="6"\n' +
    '                       ng-model="confirmPassword"\n' +
    '                       ng-disabled="transaction.busy()" pip-clear-errors\n' +
    '                       compare-to="data.password"\n' +
    '                       ng-keypress="onEnter($event)"\n' +
    '                       pip-test="input-password"/>\n' +
    '\n' +
    '                <div class="hint" ng-if="touchedErrorsWithHint(form, form.passwordConfirm).hint && !hideObject.hint">\n' +
    '                    {{::\'HINT_PASSWORD\' | translate}}\n' +
    '                </div>\n' +
    '                <div ng-messages="touchedErrorsWithHint(form, form.passwordConfirm)"  md-auto-hide="false">\n' +
    '                    <div ng-message="compareTo">\n' +
    '                        {{::\'PASSWORD_MATCH\' | translate}}\n' +
    '                    </div>\n' +
    '                    <div ng-message="required">\n' +
    '                        {{::\'HINT_PASSWORD\' | translate}}\n' +
    '                    </div>\n' +
    '                    <div ng-message="minlength">\n' +
    '                        {{::\'HINT_PASSWORD\' | translate}}\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <p class="text-small-secondary" ng-if="!hideObject.agreement">\n' +
    '                {{::\'SIGNUP_TEXT_11\' | translate}}\n' +
    '                <a href="#/legal/privacy">{{::\'SIGNUP_PRIVACY\' | translate}}</a>\n' +
    '                {{::\'SIGNUP_TEXT_12\' | translate}}\n' +
    '                <a href="#/legal/services">{{::\'SIGNUP_SERVICES\' | translate}}</a>\n' +
    '            </p>\n' +
    '\n' +
    '            <md-button ng-hide="transaction.busy()" class="md-raised m0  md-accent w-stretch"\n' +
    '                       ng-click="onSignup()" aria-label="SIGNUP"\n' +
    '                       ng-disabled="form.$invalid || (form.$pristine && !data.email) || data.serverUrl.length == 0\n' +
    '                               || data.email.length == 0 || (!data.password)\n' +
    '                               || (!data.name) || data.name.length == 0 || data.password.length == 0">\n' +
    '                {{::\'SIGNUP_TITLE\' | translate}}\n' +
    '            </md-button>\n' +
    '\n' +
    '            <md-button ng-show="transaction.busy()" ng-click="transaction.abort()"\n' +
    '                       class="md-raised md-warn m0 w-stretch" aria-label="ABORT">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '\n' +
    '            <div class="tm24 layout-row" ng-if="$mdMedia(\'gt-xs\') && !hideObject.signin">\n' +
    '                <p class="text-small m0">\n' +
    '                    {{::\'SIGNUP_TEXT_2\' | translate}}\n' +
    '                    <a href="" ng-click="gotoSignin()">\n' +
    '                        {{::\'SIGNUP_SIGNIN_HERE\' | translate}}\n' +
    '                    </a>\n' +
    '                </p>\n' +
    '            </div>\n' +
    '            <div class="tm24 divider-top" ng-if="$mdMedia(\'xs\') && !hideObject.signin"\n' +
    '                 style="margin-right: -16px; margin-left: -16px; background-color: rgb(238, 238, 238);">\n' +
    '                <div class="h48 layout-row layout-align-center-end">\n' +
    '                    <p class="bm0 text-small">{{::\'SIGNUP_TEXT_2\' | translate}}</p>\n' +
    '                </div>\n' +
    '                <div class="h48 layout-row layout-align-center-start">\n' +
    '                    <p class="bm0 text-small"><a href="" ng-click="gotoSignin()">\n' +
    '                        {{::\'SIGNUP_SIGNIN_HERE\' | translate}}</a></p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('verify_email/verify_email.html',
    '<!--\n' +
    '@file Verify email page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="pip-card-container pip-outer-scroll pip-entry">\n' +
    '    <pip-card width="400">\n' +
    '        <div class="pip-body">\n' +
    '            <div class="pip-content">\n' +
    '                <md-progress-linear ng-show="transaction.busy()" md-mode="indeterminate" class="pip-progress-top" >\n' +
    '                </md-progress-linear>\n' +
    '\n' +
    '                <h2>{{\'VERIFY_EMAIL_TITLE\' | translate}}</h2>\n' +
    '\n' +
    '                <p class="title-padding">{{\'VERIFY_EMAIL_TEXT_1\' | translate}} </p>\n' +
    '\n' +
    '                <form name=\'form\' novalidate>\n' +
    '                    <div ng-messages="form.$serverError" class="text-error bm8">\n' +
    '                        <div ng-message="ERROR_1000">{{::\'ERROR_1000\' | translate}}</div>\n' +
    '                        <div ng-message="ERROR_1110">{{::\'ERROR_1110\' | translate}}</div>\n' +
    '                        <div ng-message="ERROR_1111">{{::\'ERROR_1111\' | translate}}</div>\n' +
    '                        <div ng-message="ERROR_1112">{{::\'ERROR_1112\' | translate}}</div>\n' +
    '                        <div ng-message="ERROR_-1">{{::\'ERROR_SERVER\' | translate}}</div>\n' +
    '                        <div ng-message="ERROR_UNKNOWN">\n' +
    '                            {{ form.$serverError.ERROR_UNKNOWN | translate }}\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <a ng-hide="showServerUrl || fixedServerUrl" ng-click="showServerUrl = true" href="">      \n' +
    '                        {{\'ENTRY_CHANGE_SERVER\' | translate}}\n' +
    '                    </a>\n' +
    '                    <div ng-show="showServerUrl">\n' +
    '                        <md-autocomplete\n' +
    '                                ng-initial autofocus tabindex="1"\n' +
    '                                class="pip-combobox w-stretch bm8"\n' +
    '                                name="server"\n' +
    '                                ng-enabled="!transaction.busy()"\n' +
    '                                placeholder="{{::\'ENTRY_SERVER_URL\' | translate}}"\n' +
    '                                md-no-cache="true"\n' +
    '                                md-selected-item="data.serverUrl"\n' +
    '                                md-search-text="selected.searchURLs"\n' +
    '                                md-items="item in getMatches()"\n' +
    '                                md-item-text="item"\n' +
    '                                md-selected-item-change="onServerUrlChanged()"\n' +
    '                                md-delay="200"\n' +
    '                                ng-model="data.serverUrl"\n' +
    '                                pip-clear-errors>\n' +
    '                            <span md-highlight-text="selected.searchURLs">{{item}}</span>\n' +
    '                        </md-autocomplete>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <md-input-container class="pip-no-hint" style="padding-bottom: 4px!important;">\n' +
    '                        <label>{{::\'EMAIL\' | translate}}</label>\n' +
    '                        <input name="email" type="email" ng-model="data.email" required step="any"  pip-clear-errors\n' +
    '                               ng-disabled="transaction.busy()" tabindex="2" />\n' +
    '\n' +
    '                        <div ng-messages="touchedErrorsWithHint(form, form.email)" ng-if="!form.email.$pristine" class="md-input-error">\n' +
    '                            <div ng-message="hint" class="pip-input-hint">{{::\'HINT_EMAIL\' | translate}}</div>\n' +
    '                            <div ng-message="required">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                            <div ng-message="email">{{::\'ERROR_EMAIL_INVALID\' | translate }}</div>\n' +
    '                            <div ng-message="ERROR_1100">{{::\'ERROR_1100\' | translate}}</div>\n' +
    '                            <div ng-message="ERROR_1104">{{::\'ERROR_1104\' | translate}}</div>\n' +
    '                            <div ng-message="ERROR_1305">{{::\'ERROR_1305\' | translate}}</div>\n' +
    '                            <div ng-message="ERROR_1106">{{::\'ERROR_1106\' | translate}}</div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <md-input-container class="pip-no-hint">\n' +
    '                        <label>{{::\'ENTRY_RESET_CODE\' | translate}}</label>\n' +
    '                        <input name="code" ng-disabled="transaction.busy()"\n' +
    '                               ng-model="data.code" required tabindex="3" />\n' +
    '\n' +
    '                        <div ng-messages="touchedErrorsWithHint(form, form.code)" ng-if="!form.fullName.$pristine" class="md-input-error">\n' +
    '                            <div ng-message="hint" class="pip-input-hint">{{::\'ENTRY_RESET_CODE\' | translate}}</div>\n' +
    '                            <div ng-message="required">{{::\'ERROR_CODE_INVALID\' | translate }}</div>\n' +
    '                            <div ng-message="ERROR_1108">{{::\'ERROR_1108\' | translate}}</div>\n' +
    '                        </div>\n' +
    '                    </md-input-container>\n' +
    '\n' +
    '                    <p> \n' +
    '                        {{\'VERIFY_EMAIL_TEXT_21\' | translate}} \n' +
    '                        <a ng-click="onRecover()" class="pointer" href="">{{\'VERIFY_EMAIL_RESEND\' | translate}}</a>\n' +
    '                        {{\'VERIFY_EMAIL_TEXT_22\' | translate}} \n' +
    '                    </p>\n' +
    '                </form>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="pip-footer">\n' +
    '            <md-button ng-click="goBack()" ng-hide="transaction.busy()" class="rm8" aria-label="CANCEL">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '            <md-button class="md-accent" ng-click="onVerify()" ng-hide="transaction.busy()" aria-label="VERIFY"\n' +
    '                ng-disabled="data.code.length == 0 || data.email.length == 0 || (!data.email && form.$pristine) || (!data.code)">\n' +
    '                {{::\'VERIFY\' | translate}}\n' +
    '            </md-button>\n' +
    '            <md-button class="md-warn " ng-show="transaction.busy()" ng-click="transaction.abort()" aria-label="ABORT">\n' +
    '                {{::\'CANCEL\' | translate}}\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </pip-card>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipEntry.Templates');
} catch (e) {
  module = angular.module('pipEntry.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('verify_email/verify_email_success.html',
    '<!--\n' +
    '@file Email verification success page\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="pip-card-container pip-outer-scroll pip-entry">\n' +
    '    <pip-card width="400">\n' +
    '        <div class="pip-footer">\n' +
    '            <md-button ng-click="onContinue()" class="md-accent">\n' +
    '                {{\'CONTINUE\' | translate}} \n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <div class="pip-body">\n' +
    '            <div class="pip-content">\n' +
    '                <h2>{{\'VERIFY_EMAIL_TITLE\' | translate}}</h2>\n' +
    '\n' +
    '                <p class="title-padding">\n' +
    '                    {{\'VERIFY_EMAIL_SUCCESS_TEXT\' | translate}} \n' +
    '                </p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </pip-card>\n' +
    '</div>');
}]);
})();
