/**
 * @file Registration of location WebUI controls
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    angular.module('pipLocations', [        
        'pipLocation',
        'pipLocationMap',
        'pipLocationIp',
        'pipLocationEdit',
        'pipLocationEditDialog'
    ]);
    
})();


