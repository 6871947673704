(function(module) {
try {
  module = angular.module('pipComposite.Templates');
} catch (e) {
  module = angular.module('pipComposite.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('checklist_edit/checklist_edit.html',
    '<!--\n' +
    '@file Checklist edit control content\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div ng-class="{\'pip-checklist-draggable\': selected.drag}" id="{{\'checklist-\'  + selected.id}}">\n' +
    '    <div ng-repeat="item in checklistContent"\n' +
    '         ng-mousedown="onClick($event, $index)"\n' +
    '         class="pip-checklist-item"\n' +
    '         id="{{\'check-item-\'  + selected.id + \'-\' +  $index}}"\n' +
    '         pip-drag="checklistContent.length > 1 && selected.drag && !item.empty"\n' +
    '         pip-drag-data="item"\n' +
    '         pip-force-touch = "true"\n' +
    '         pip-touch-delay="30"\n' +
    '         pip-drop="true"\n' +
    '         pip-drag-stop="onStop(selected.id)"\n' +
    '         pip-drag-start="onStart(selected.id)"\n' +
    '         pip-scroll-container="pipScrollContainer"\n' +
    '         pip-drop-success="onDropComplete($index, $data, $event, selected.id)">\n' +
    '\n' +
    '        <div ng-class="{\'put_place\': selected.drag}"></div>\n' +
    '        <div class="pip-checklist-item-body layout-row layout-align-start-start"\n' +
    '             pip-cancel-drag="true"\n' +
    '             ng-class="{ \'select-active-item\': isSelectedItem($index) }">\n' +
    '\n' +
    '            <div class="pip-checklist-button"  pip-cancel-drag="true">\n' +
    '                <md-button pip-drag-handle\n' +
    '                           class="pip-icon-checklist-button md-icon-button no-ripple-container"\n' +
    '                           aria-label="REARRANGE"\n' +
    '                           tabindex="-1"\n' +
    '                           pip-mobile-mousedown="onDownDragg(item)"\n' +
    '                           pip-mobile-mouseup="onDraggEnd()"\n' +
    '                           ng-if="pipDraggable && checklistContent.length > 2 && !item.empty"\n' +
    '                           ng-class="checklistContent.length > 1 ? \'cursor-move\' : \'cursor-default\'"\n' +
    '                           ng-disabled="ngDisabled()">\n' +
    '                    <md-icon class="text-grey" md-svg-icon="icons:vhandle"></md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '            <div class="pip-checklist-button" style="overflow: hidden"  pip-cancel-drag="true">\n' +
    '                <div class="pip-checklist-button-container">\n' +
    '                    <md-button class="pip-icon-checklist-button md-icon-button"\n' +
    '                               ng-show="item.empty"\n' +
    '                               ng-disabled="ngDisabled()"\n' +
    '                               md-ink-ripple\n' +
    '                               ng-click="onAddItem()"\n' +
    '                               tabindex="-1"\n' +
    '                               aria-label="PLUS">\n' +
    '                        <md-icon class="text-grey" md-svg-icon="icons:plus"></md-icon>\n' +
    '                    </md-button>\n' +
    '                    <md-checkbox ng-model="item.checked"\n' +
    '                                 ng-show="!item.empty"\n' +
    '                                 aria-label="COMPLETE"\n' +
    '                                 pip-cancel-drag="true"\n' +
    '                                 ng-focus="onItemFocus($index)"\n' +
    '                                 ng-change="onChecked(item)"\n' +
    '                                 ng-disabled="ngDisabled()">\n' +
    '                    </md-checkbox>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="pip-checklist-text flex " pip-cancel-drag="true">\n' +
    '                <md-input-container md-no-float class="flex" >\n' +
    '                    <textarea ng-model="item.text"\n' +
    '                              name="{{\'text\' + $index}}"\n' +
    '                              aria-label="TEXT"\n' +
    '                              class="pip-text-checkbox"\n' +
    '                              ng-focus="onItemFocus($index)"\n' +
    '                              ng-change="onChangeItem($index)"\n' +
    '                              ng-keydown="onTextareaKeyDown($event, $index, item)"\n' +
    '                              placeholder="{{::\'TEXT\' | translate}}"\n' +
    '                              id="{{\'check-item-text-\' + selected.id + \'-\' + $index}}"\n' +
    '                              ng-disabled="ngDisabled()">\n' +
    '                    </textarea>\n' +
    '                </md-input-container>\n' +
    '            </div>\n' +
    '            <div class="pip-checklist-button"  pip-cancel-drag="true">\n' +
    '                <md-button class="pip-icon-checklist-button md-icon-button" md-ink-ripple\n' +
    '                           ng-click="onDeleteItem($index, item)"\n' +
    '                           ng-disabled="ngDisabled()"\n' +
    '                           tabindex="-1"\n' +
    '                           ng-focus="onItemFocus($index)"\n' +
    '                           ng-show="isSelectedItem($index)"\n' +
    '                           aria-label="DELETE-ITEM">\n' +
    '                    <md-icon class="text-grey" md-svg-icon="icons:cross-circle"></md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div id="{{\'check-item-empty-\' + selected.id}}"\n' +
    '         class="pip-empty-text"\n' +
    '         pip-drag="false"\n' +
    '         pip-drop="true"\n' +
    '         pip-drop-success="onDropComplete(checklistContent.length, $data, $event, selected.id)">\n' +
    '        <div ng-class="{\'put_place\': selected.drag}"></div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipComposite.Templates');
} catch (e) {
  module = angular.module('pipComposite.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('checklist_view/checklist_view.html',
    '<div ng-repeat="item in checklistContent track by $index">\n' +
    '    <div class="pip-checklist-item layout-row layout-align-start-start">\n' +
    '        <div class="pip-checklist-icon">\n' +
    '            <md-checkbox  ng-model="item.checked"\n' +
    '                          ng-click="onClick($event, item)"\n' +
    '                          aria-label="COMPLETE"\n' +
    '                          ng-disabled="ngDisabled()">\n' +
    '            </md-checkbox>\n' +
    '        </div>\n' +
    '        <div class="pip-checklist-text flex">\n' +
    '            <pip-markdown pip-text="item.text"\n' +
    '                          pip-rebind="true"\n' +
    '                          ng-disabled="true">\n' +
    '            </pip-markdown>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipComposite.Templates');
} catch (e) {
  module = angular.module('pipComposite.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('composite_edit/composite_edit.html',
    '<!--\n' +
    '@file Composite edit control content\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="divider-top">\n' +
    '    <div class="pip-composite-body"\n' +
    '         ng-show="compositeContent.length != 0"\n' +
    '         ng-class="{\'drag-active\': selected.drag}">\n' +
    '\n' +
    '        <div class="pip-composite-item"\n' +
    '             ng-repeat="obj in compositeContent track by obj.id"\n' +
    '             ng-mousedown="onClick($event, $index, obj)"\n' +
    '             ng-class="{\'selected-content\': isSelectedSection($index, obj),\n' +
    '                        \'composite-animate\': !obj.empty && compositeContent.length > 1}"\n' +
    '             ng-keyup="onKeyUp($event)"\n' +
    '             ng-keydown="onKeyDown($event, $index, obj)"\n' +
    '             pip-drag="compositeContent.length > 1 && selected.drag"\n' +
    '             pip-touch-delay="10"\n' +
    '             pip-drag-data="obj"\n' +
    '             pip-scroll-container="pipScrollContainer"\n' +
    '             pip-drop="true"\n' +
    '             pip-force-touch = "true"\n' +
    '             pip-drag-stop="onStop(selected.id)"\n' +
    '             pip-drag-start="onStart(selected.id)"\n' +
    '             pip-drop-success="onDropComplete($index, $data, $event, selected.id)"\n' +
    '             id="{{\'composite-item-\' + selected.id + \'-\' + $index}}">\n' +
    '\n' +
    '            <!--<div ng-class="{\'putt_box\': selected.drag}"></div>-->\n' +
    '            <div class="putt_box"></div>\n' +
    '            <div class="pip-section-header layout-row layout-align-start-center"\n' +
    '                 ng-if="!obj.empty">\n' +
    '                <div class="w38"></div>\n' +
    '                <md-button class="md-icon-button md-icon-button-little icon-rearrange-btn no-ripple-container rm8 cursor-move"\n' +
    '                           ng-if="!ngDisabled() && compositeContent.length > 1"\n' +
    '                           pip-drag-handle\n' +
    '                           pip-mobile-mousedown="onDownDragg($event, obj)"\n' +
    '                           pip-mobile-mouseup="onDraggEnd()"\n' +
    '                           tabindex="-1"\n' +
    '                           aria-label="COMPOSITE-DRAGG"\n' +
    '                           ng-hide="compositeContent.length == 1">\n' +
    '                    <md-icon class="composite-icon cursor-move" md-svg-icon="icons:handle"></md-icon>\n' +
    '                </md-button>\n' +
    '                <div>\n' +
    '                    <md-button class="md-icon-button md-icon-button-little rm8"\n' +
    '                               ng-click="onDeleteItem($index)"\n' +
    '                               ng-disabled="ngDisabled()"\n' +
    '                               aria-label="COMPOSITE-DELETE">\n' +
    '                        <md-icon class="composite-icon" md-svg-icon="icons:cross"></md-icon>\n' +
    '                    </md-button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <!--pip-prevent-drag-->\n' +
    '            <!-- for text -->\n' +
    '            <div class="pip-section-content rp24-flex lp24-flex tp16 bp16"\n' +
    '                 ng-if="obj.type == \'text\'" pip-cancel-drag="true">\n' +
    '                <md-input-container class="p0 m0 w-stretch" md-no-float>\n' +
    '                            <textarea ng-model="obj.text" aria-label="text"\n' +
    '                                      placeholder="{{ isFirst ? compositePlaceholder : \'TEXT\' | translate}}"\n' +
    '                                      id="{{\'composite-item-text-\' + selected.id + \'-\' + $index}}"\n' +
    '                                      ng-change="onContentChange(obj)"\n' +
    '                                      pip-cancel-drag="true"\n' +
    '                                      ng-disabled="ngDisabled()">\n' +
    '                            </textarea>\n' +
    '                </md-input-container>\n' +
    '            </div>\n' +
    '            <!-- -->\n' +
    '            <div class="pip-section-content rp24-flex lp24-flex vp20"\n' +
    '                 ng-if="obj.type == \'pictures\'" pip-cancel-drag="true">\n' +
    '                <pip-picture-list-edit class="w-stretch"\n' +
    '                                       pip-cancel-drag="true"\n' +
    '                                       pip-picture-ids="obj.pic_ids"\n' +
    '                                       pip-changed="onContentChange(obj)"\n' +
    '                                       pip-created="obj.pictures = $event.sender"\n' +
    '                                       pip-cancel-drag="true"\n' +
    '                                       pip-added-picture="addedContent"\n' +
    '                                       ng-disabled="ngDisabled()">\n' +
    '                </pip-picture-list-edit>\n' +
    '            </div>\n' +
    '            <!-- -->\n' +
    '            <div class="pip-section-content rp24-flex lp24-flex vp20"\n' +
    '                 ng-if="obj.type == \'documents\'" pip-cancel-drag="true">\n' +
    '                <pip-document-list-edit class="w-stretch"\n' +
    '                                        pip-documents="obj.docs"\n' +
    '                                        pip-cancel-drag="true"\n' +
    '                                        pip-changed="onContentChange(obj)"\n' +
    '                                        pip-cancel-drag="true"\n' +
    '                                        pip-created="obj.documents = $event.sender"\n' +
    '                                        pip-added-document="addedContent"\n' +
    '                                        ng-disabled="ngDisabled()">\n' +
    '                </pip-document-list-edit>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="pip-section-content layout-row layout-align-start-center"\n' +
    '                 ng-if="obj.type == \'checklist\'" pip-cancel-drag="true">\n' +
    '                <pip-checklist-edit pip-options="obj.checklist"\n' +
    '                                    pip-draggable="isActiveChecklist(obj)"\n' +
    '                                    pip-changed="onContentChange(obj)"\n' +
    '                                    ng-disabled="ngDisabled()"\n' +
    '                                    pip-scroll-container="{{pipScrollContainer}}"\n' +
    '                                    pip-rebind="true">\n' +
    '                </pip-checklist-edit>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="pip-section-content vp20 rp24-flex lp24-flex"\n' +
    '                 ng-if="obj.type == \'location\'" pip-cancel-drag="true">\n' +
    '                <pip-location-edit class="pip-location-attachments w-stretch"\n' +
    '                                   pip-location-name="obj.loc_name"\n' +
    '                                   pip-location-pos="obj.loc_pos"\n' +
    '                                   pip-cancel-drag="true"\n' +
    '                                   pip-location-holder="pipLocationHolder"\n' +
    '                                   pip-changed="onContentChange(obj)"\n' +
    '                                   ng-disabled="ngDisabled()">\n' +
    '                </pip-location-edit>\n' +
    '            </div>\n' +
    '            <!-- -->\n' +
    '            <div class="pip-section-content bp16-flex rp24-flex lp24-flex tp20"\n' +
    '                 ng-if="obj.type == \'time\'" pip-cancel-drag="true">\n' +
    '                <pip-time-range-edit class="w-stretch"\n' +
    '                               pip-start-date="obj.start"\n' +
    '                               pip-end-date="obj.end"\n' +
    '                               pip-size="$sizeGtSmall"\n' +
    '                               pip-changed="onContentChange(obj)"\n' +
    '                               ng-disabled="ngDisabled()"\n' +
    '                               pip-start-label="{{ \'COMPOSITE_START_TIME\' | translate }}"\n' +
    '                               pip-end-label="{{ \'COMPOSITE_END_TIME\' | translate }}">\n' +
    '                </pip-time-range-edit>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="pip-composite-item w-stretch"\n' +
    '             pip-drag="false"\n' +
    '             pip-drop="true"\n' +
    '             pip-drop-success="onDropComplete(compositeContent.length, $data, $event, selected.id)"\n' +
    '             pip-drag-stop="onStop(selected.id)"\n' +
    '             pip-drag-start="onStart(selected.id)"\n' +
    '             id="{{\'pip-composite-last-\' + selected.id}}">\n' +
    '\n' +
    '            <!--<div ng-class="{\'putt_box\': selected.drag}"></div>-->\n' +
    '            <div class="putt_box"></div>\n' +
    '            <div class="pip-section-content h24" style="border: 0px!important;">\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipComposite.Templates');
} catch (e) {
  module = angular.module('pipComposite.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('composite_summary/composite_summary.html',
    '<div ng-repeat="item in compositeContent track by $index">\n' +
    '\n' +
    '    <!-- for text -->\n' +
    '    <div class="pip-composite-text" ng-if="item.type == \'text\' && item.text">\n' +
    '        <pip-markdown pip-text="item.text"\n' +
    '                      pip-line-count="{{textSize}}"\n' +
    '                      pip-rebind="true"\n' +
    '                      ng-disabled="true">\n' +
    '        </pip-markdown>\n' +
    '    </div>\n' +
    '    <!-- for pictures -->\n' +
    '    <div ng-if="item.type == \'pictures\' && item.pic_ids.length > 0"\n' +
    '         ng-class=" compositeContent[$index - 1].type != \'pictures\' ?\n' +
    '                    compositeContent[$index + 1].type != \'pictures\' ? \'tm16 bm16\' : \'tm16 bm0\' :\n' +
    '                    compositeContent[$index + 1].type != \'pictures\' ? \'tm8 bm16\' : \'tm8 bm0\' "\n' +
    '         class="pip-composite-pictures">\n' +
    '        <pip-collage ng-if="rebind"\n' +
    '                     pip-picture-ids="item.pic_ids"\n' +
    '                     pip-unique-code="item.id"\n' +
    '                     pip-multiple="true"\n' +
    '                     pip-open="disableControl"\n' +
    '                     pip-rebind="true"\n' +
    '                     ng-disabled="disableControl">\n' +
    '        </pip-collage>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- for documents -->\n' +
    '    <div ng-if="item.type == \'documents\' && item.docs.length > 0"\n' +
    '         class="pip-composite-documents layout-row flex">\n' +
    '        <pip-document-list class="flex"\n' +
    '                           pip-documents="item.docs"\n' +
    '                           pip-rebind="true"\n' +
    '                           pip-document-icon="true"\n' +
    '                           pip-collapse="true"\n' +
    '                           ng-disabled="disableControl">\n' +
    '        </pip-document-list>\n' +
    '    </div>\n' +
    '\n' +
    '    <!--for checklist -->\n' +
    '    <div ng-if="item.type == \'checklist\' && item.checklist.length > 0"\n' +
    '         class="pip-composite-checklist">\n' +
    '        <pip-checklist-view pip-options="item.checklist"\n' +
    '                            pip-changed="onContentChange()"\n' +
    '                            pip-rebind="true"\n' +
    '                            pip-collapse="true"\n' +
    '                            ng-disabled="disabledChecklist">\n' +
    '        </pip-checklist-view>\n' +
    '    </div>\n' +
    '\n' +
    '    <!--for location -->\n' +
    '    <div class="pip-composite-location layout-row layout-align-start-center flex"\n' +
    '         ng-if="item.type == \'location\' && (item.loc_pos || item.loc_name)">\n' +
    '\n' +
    '        <pip-location class="flex"\n' +
    '                      pip-location-name="item.loc_name"\n' +
    '                      pip-location-pos="item.loc_pos"\n' +
    '                      pip-collapse="true"\n' +
    '                      pip-show-location-icon="true"\n' +
    '                      ng-disabled="disableControl"\n' +
    '                      pip-rebind="true">\n' +
    '        </pip-location>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- for time -->\n' +
    '    <div class="pip-composite-time layout-row layout-align-start-center flex"\n' +
    '         ng-if="item.type == \'time\' && (item.start || item.end)">\n' +
    '\n' +
    '        <md-icon md-svg-icon="icons:time" class="rm24 lm0"></md-icon>\n' +
    '        <pip-time-range\n' +
    '                pip-start-date="item.start"\n' +
    '                pip-end-date="item.end"\n' +
    '                pip-rebind="true"\n' +
    '                ng-disabled="disableControl">\n' +
    '        </pip-time-range>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipComposite.Templates');
} catch (e) {
  module = angular.module('pipComposite.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('composite_toolbar/composite_toolbar.html',
    '<!--\n' +
    '@file Composite toolbar control content\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="layout-row layout-align-start-start flex">\n' +
    '    <md-button class="pip-composite-button"\n' +
    '               ng-if="!emptyState"\n' +
    '               ng-class="{ \'remove-item\': !emptyState ,\n' +
    '                                \'new-item\': !emptyState }"\n' +
    '               ng-click="onAddItem(\'text\');"\n' +
    '               aria-label="COMPOSITE-BUTTON-TEXT"\n' +
    '               ng-disabled="ngDisabled()">\n' +
    '        <md-icon class="icon-text-block" md-svg-icon="icons:text"></md-icon>\n' +
    '        <md-tooltip>{{::\'TEXT\'| translate}}</md-tooltip>\n' +
    '    </md-button>\n' +
    '    <md-button ng-if="toolbarButton.checklist"\n' +
    '               ng-click="onAddItem(\'checklist\')"\n' +
    '               ng-disabled="ngDisabled()"\n' +
    '               class="pip-composite-button"\n' +
    '               aria-label="COMPOSITE-BUTTON-CHECKLIST">\n' +
    '        <md-icon class="icon-checkbox-on" md-svg-icon="icons:checkbox-on"></md-icon>\n' +
    '        <md-tooltip>{{::\'CHECKLIST\'| translate}}</md-tooltip>\n' +
    '    </md-button>\n' +
    '    <md-button ng-if="toolbarButton.picture"\n' +
    '               ng-click="onAddItem(\'pictures\')"\n' +
    '               ng-disabled="ngDisabled()"\n' +
    '               class="pip-composite-button"\n' +
    '               aria-label="COMPOSITE-BUTTON-PICTURES">\n' +
    '        <md-icon class="icon-camera" md-svg-icon="icons:camera"></md-icon>\n' +
    '        <md-tooltip>{{::\'PICTURE\'| translate}}</md-tooltip>\n' +
    '    </md-button>\n' +
    '    <md-button ng-click="onAddItem(\'documents\')"\n' +
    '               ng-if="toolbarButton.document"\n' +
    '               ng-disabled="ngDisabled()"\n' +
    '               class="pip-composite-button"\n' +
    '               aria-label="COMPOSITE-BUTTON-DOCUMENTS">\n' +
    '        <md-icon class="icon-document" md-svg-icon="icons:document"></md-icon>\n' +
    '        <md-tooltip>{{::\'DOCUMENT\'| translate}}</md-tooltip>\n' +
    '    </md-button>\n' +
    '    <md-button ng-click="onAddItem(\'location\')"\n' +
    '               ng-if="toolbarButton.location"\n' +
    '               ng-disabled="ngDisabled()"\n' +
    '               class="pip-composite-button"\n' +
    '               aria-label="COMPOSITE-BUTTON-LOCATIONS">\n' +
    '        <md-icon class="icon-location" md-svg-icon="icons:location"></md-icon>\n' +
    '        <md-tooltip>{{::\'LOCATION\'| translate}}</md-tooltip>\n' +
    '    </md-button>\n' +
    '    <md-button ng-click="onAddItem(\'time\')"\n' +
    '               ng-if="toolbarButton.event"\n' +
    '               ng-disabled="ngDisabled()"\n' +
    '               class="pip-composite-button"\n' +
    '               aria-label="COMPOSITE-BUTTON-TIME">\n' +
    '        <md-icon class="icon-time" md-svg-icon="icons:time"></md-icon>\n' +
    '        <md-tooltip>{{::\'TIME\'| translate}}</md-tooltip>\n' +
    '    </md-button>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipComposite.Templates');
} catch (e) {
  module = angular.module('pipComposite.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('composite_view/composite_view.html',
    '<div ng-repeat="item in compositeContent track by $index">\n' +
    '\n' +
    '    <!-- for text -->\n' +
    '    <div class="pip-composite-text lp24-flex rp24-flex" ng-if="item.type == \'text\' && item.text" ng-class="{\'bm16\': $last}">\n' +
    '        <pip-markdown pip-text="item.text"\n' +
    '                      pip-rebind="true"\n' +
    '                      ng-disabled="true">\n' +
    '        </pip-markdown>\n' +
    '    </div>\n' +
    '    <!-- for pictures -->\n' +
    '    <div ng-if="item.type == \'pictures\' && item.pic_ids.length > 0"\n' +
    '         ng-class=" compositeContent[$index - 1].type != \'pictures\' ?\n' +
    '                    compositeContent[$index + 1].type != \'pictures\' ? \'tm16 bm16\' : \'tm16 bm0\' :\n' +
    '                    compositeContent[$index + 1].type != \'pictures\' ? \'tm8 bm16\' : \'tm8 bm0\' "\n' +
    '         class="pip-composite-pictures lp24-flex rp24-flex">\n' +
    '        <pip-collage ng-if="rebind"\n' +
    '                pip-picture-ids="item.pic_ids"\n' +
    '                pip-unique-code="item.id"\n' +
    '                pip-multiple="true"\n' +
    '                pip-open="true"\n' +
    '                pip-rebind="true"\n' +
    '                ng-disabled="ngDisabled()">\n' +
    '        </pip-collage>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- for documents -->\n' +
    '    <div ng-if="item.type == \'documents\' && item.docs.length > 0"\n' +
    '         class="pip-composite-documents layout-row layout-align-start-start flex">\n' +
    '        <pip-document-list pip-documents="item.docs"\n' +
    '                           pip-document-icon="true"\n' +
    '                           pip-rebind="true"\n' +
    '                           ng-disabled="ngDisabled()">\n' +
    '        </pip-document-list>\n' +
    '    </div>\n' +
    '\n' +
    '    <!--for checklist -->\n' +
    '    <div ng-if="item.type == \'checklist\' && item.checklist.length > 0"\n' +
    '         class="pip-composite-checklist lp24-flex rp24-flex">\n' +
    '        <pip-checklist-view pip-options="item.checklist"\n' +
    '                            pip-changed="onContentChange()"\n' +
    '                            pip-rebind="true"\n' +
    '                            ng-disabled="isDisabled()">\n' +
    '        </pip-checklist-view>\n' +
    '    </div>\n' +
    '\n' +
    '    <!--for location -->\n' +
    '    <div class="pip-composite-location layout-row layout-align-start-start flex"\n' +
    '         ng-if="item.type == \'location\' && (item.loc_pos || item.loc_name)">\n' +
    '\n' +
    '        <pip-location class="flex"\n' +
    '                      pip-location-name="item.loc_name"\n' +
    '                      pip-location-pos="item.loc_pos"\n' +
    '                      pip-show-location-icon="true"\n' +
    '                      pip-collapse="false"\n' +
    '                      ng-disabled="ngDisabled()"\n' +
    '                      pip-rebind="true">\n' +
    '        </pip-location>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- for time -->\n' +
    '    <div class="pip-composite-time lp24-flex rp24-flex layout-row layout-align-start-center  flex"\n' +
    '         ng-if="item.type == \'time\'">\n' +
    '\n' +
    '        <md-icon md-svg-icon="icons:time" class="lm0"></md-icon>\n' +
    '        <pip-time-range\n' +
    '                pip-start-date="item.start"\n' +
    '                pip-end-date="item.end"\n' +
    '                pip-rebind="true"\n' +
    '                ng-disabled="ngDisabled()">\n' +
    '        </pip-time-range>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipComposite.Templates');
} catch (e) {
  module = angular.module('pipComposite.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('content_switch/content_switch.html',
    '<!--\n' +
    '@file Content switch control content\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-button ng-click="showPictures = !showPictures; onButtonClick(\'pictures\')"\n' +
    '           aria-label="showPictures"\n' +
    '           class="md-icon-button"\n' +
    '           ng-show="showIconPicture"\n' +
    '           ng-disabled="transaction.busy()">\n' +
    '        <md-icon class="active-camera"\n' +
    '                 ng-class="{ \'active-camera\': showPictures }"\n' +
    '                 md-svg-icon="icons:camera"></md-icon>\n' +
    '</md-button>\n' +
    '<md-button ng-click="showDocuments = !showDocuments; onButtonClick(\'documents\')"\n' +
    '           aria-label="showDocuments"\n' +
    '           class="md-icon-button"\n' +
    '           ng-show="showIconDocument"\n' +
    '           ng-disabled="transaction.busy()">\n' +
    '    <md-icon ng-class="{ \'active-document\': showDocuments }" md-svg-icon="icons:document"></md-icon>\n' +
    '</md-button>\n' +
    '<md-button ng-click="showEvent = !showEvent; onButtonClick(\'event\')"\n' +
    '           aria-label="showEvent"\n' +
    '           class="md-icon-button"\n' +
    '           ng-show="showIconEvent"\n' +
    '           ng-disabled="transaction.busy()">\n' +
    '    <md-icon ng-class="{ \'active-time\': showEvent }" md-svg-icon="icons:time"></md-icon>\n' +
    '</md-button>\n' +
    '<md-button ng-click="showLocation = !showLocation; onButtonClick(\'location\')"\n' +
    '           aria-label="showLocation"\n' +
    '           class="md-icon-button"\n' +
    '           ng-show="showIconLocation"\n' +
    '           ng-disabled="transaction.busy()">\n' +
    '    <md-icon ng-class="{ \'active-location\': showLocation }" md-svg-icon="icons:location"></md-icon>\n' +
    '</md-button>');
}]);
})();
