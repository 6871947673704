/**
 * @file Registration of all WebUI core services
 * @copyright Digital Living Software Corp. 2014-2016
 */

/* global angular */

(function () {
    'use strict';

    angular.module('pipCore', [
        'pipUtils',
        'pipErrors',
	    'pipTransactions',
	    'pipTranslate',
        'pipState',
        'pipTimer',
        'pipAssert',
        'pipDebug',
        'pipDateFormat',
        'pipDateTimeFilters',
        'pipTranslateFilters',
        'pipClearErrors',
        'pipTheme',

        'pipFocused',
        'pipSelected',
        'pipInfiniteScroll',
        'pipDraggable',
        'pipUnsavedChanges',
        'pipFabTooltipVisibility',
    ]);
    
})();