(function(module) {
try {
  module = angular.module('pipPictures.Templates');
} catch (e) {
  module = angular.module('pipPictures.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('add_image/add_image.html',
    ' <!--\n' +
    '@file Add image directive content\n' +
    '@copyright Digital Living Software Corp. 2014-2015\n' +
    '-->\n' +
    '\n' +
    '<md-menu>\n' +
    '        <ng-transclude class="pip-add-image-open-button" ng-click="ngDisabled() ? \'\' : $mdOpenMenu()"></ng-transclude>\n' +
    '        <md-menu-content width="4">\n' +
    '            <md-menu-item>\n' +
    '                <md-button class="layout-row layout-align-start-center" accept="image/*"\n' +
    '                           ng-keydown="onKeyDown($event)" ng-multiple="isMulti()"\n' +
    '                           ng-file-select ng-file-change="onFileChange($files)" ng-click="hideMenu()" ng-file-drop>\n' +
    '\n' +
    '                    <md-icon class="text-headline text-grey rm24-flex" md-svg-icon="icons:folder"></md-icon>\n' +
    '                    <!--<span class="icon-folder text-headline text-grey tp8 rm24-flex"></span>-->\n' +
    '                    <span class="text-grey">{{::\'FILE\' | translate}}</span>\n' +
    '                </md-button>\n' +
    '            </md-menu-item>\n' +
    '            <md-menu-item>\n' +
    '                <md-button class="layout-row layout-align-start-center" ng-click="onWebLinkClick()">\n' +
    '                    <md-icon class="text-headline text-grey rm24-flex" md-svg-icon="icons:weblink"></md-icon>\n' +
    '                    <!--<span class="icon-weblink text-headline text-grey tp8 rm24-flex"></span>-->\n' +
    '                    <span class="text-grey">{{::\'WEB_LINK\' | translate}}</span>\n' +
    '                </md-button>\n' +
    '            </md-menu-item>\n' +
    '            <md-menu-item>\n' +
    '                <md-button class="layout-row layout-align-start-center" ng-click="onCameraClick()">\n' +
    '                    <md-icon class="text-headline text-grey rm24-flex" md-svg-icon="icons:camera"></md-icon>\n' +
    '                    <!--<span class="icon-camera text-headline text-grey tp8 rm24-flex"></span>-->\n' +
    '                    <span class="text-grey">{{::\'CAMERA\' | translate}}</span>\n' +
    '                </md-button>\n' +
    '            </md-menu-item>\n' +
    '            <md-menu-item>\n' +
    '                <md-button class="layout-row layout-align-start-center" ng-click="onGalleryClick()">\n' +
    '                    <md-icon class="text-headline text-grey rm24-flex" md-svg-icon="icons:images"></md-icon>\n' +
    '                    <!--<span class="icon-images text-headline text-grey tp8 rm24-flex"></span>-->\n' +
    '                    <span class="text-grey">{{::\'IMAGE_GALLERY\' | translate}}</span>\n' +
    '                </md-button>\n' +
    '            </md-menu-item>\n' +
    '        </md-menu-content>\n' +
    '    </md-menu>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipPictures.Templates');
} catch (e) {
  module = angular.module('pipPictures.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('camera_dialog/camera_dialog.html',
    '<!--\n' +
    '@file Camera dialog content\n' +
    '@copyright Digital Living Software Corp. 2014-2015\n' +
    '-->\n' +
    '\n' +
    '<md-dialog class="pip-dialog pip-picture-dialog pip-camera-dialog layout-column" md-theme="{{theme}}"\n' +
    '           ng-show="browser != \'android\'"\n' +
    '        ng-class="{\'pip-android-dialog\': browser == \'android\' || !browser}">\n' +
    '    <div class="pip-header" class="layout-row layout-align-start-center">\n' +
    '        <md-button  ng-click="onCancelClick()" class="md-icon-button"\n' +
    '                    aria-label="{{ ::\'CANCEL\' | translate }}">\n' +
    '            <md-icon class="text-grey" md-svg-icon="icons:arrow-left"></md-icon>\n' +
    '        </md-button>\n' +
    '        <h3 class="m0 text-title">{{ ::\'TAKE_PICTURE\' | translate }}</h3>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="pip-body">\n' +
    '        <div class="camera-stream" ng-hide="webCamError || browser == \'android\'"></div>\n' +
    '        <div class="camera-error"\n' +
    '             ng-show="webCamError || browser == \'android\'"\n' +
    '             class="layout-row layout-align-center-center">\n' +
    '            <span>{{ ::\'WEB_CAM_ERROR\' | translate }}</span>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="pip-footer">\n' +
    '        <div class="w48">\n' +
    '            <md-button ng-click="onResetPicture()"\n' +
    '                       ng-hide="!$freeze || webCamError"\n' +
    '                       class="md-icon-button"\n' +
    '                       ng-disabled="transaction.busy()"\n' +
    '                       aria-label="{{ ::\'REMOVE_PICTURE\' | translate }}">\n' +
    '                <md-icon class="text-grey" md-svg-icon="icons:refresh"></md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '        <div class="flex"></div>\n' +
    '        <div class="w48">\n' +
    '            <md-button ng-click="onTakePictureClick()"\n' +
    '                       ng-hide="webCamError"\n' +
    '                       class="md-icon-button"\n' +
    '                       aria-label="{{ ::\'TAKE_PICTURE\' | translate }}">\n' +
    '                <md-icon class="text-grey icon-button" md-svg-icon="icons:{{$freeze ? \'check\' : \'camera\'}}"></md-icon>\n' +
    '            </md-button>\n' +
    '\n' +
    '        </div>\n' +
    '        <div class="flex"></div>\n' +
    '        <div class="w48">\n' +
    '            <md-button  ng-click="onCancelClick()" class="md-icon-button"\n' +
    '                        aria-label="{{ ::\'CANCEL\' | translate }}">\n' +
    '                <md-icon class="text-grey" md-svg-icon="icons:cross"></md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '</md-dialog>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipPictures.Templates');
} catch (e) {
  module = angular.module('pipPictures.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('gallery_search_dialog/gallery_search_dialog.html',
    '<md-dialog class="pip-dialog pip-gallery-search-dialog pip-picture-dialog layout-column"\n' +
    '           md-theme="{{theme}}">\n' +
    '    <md-progress-linear\n' +
    '            ng-show="transaction.busy()" md-mode="indeterminate">\n' +
    '    </md-progress-linear>\n' +
    '\n' +
    '    <md-dialog-content class="pip-body lp0 rp0 tp0 pip-scroll flex layout-row">\n' +
    '        <div class="layout-column layout-align-start-start flex">\n' +
    '            <div class="pip-header w-stretch layout-column layout-align-start-start">\n' +
    '                <h3 class="w-stretch text-title m0 bp8">\n' +
    '                    <md-button  class="md-icon-button m0"\n' +
    '                                ng-click="onCancelClick()"\n' +
    '                                aria-label="{{ ::\'CANCEL\' | translate }}">\n' +
    '                        <md-icon class="text-grey" md-svg-icon="icons:arrow-left"></md-icon>\n' +
    '                    </md-button>\n' +
    '                    {{::\'IMAGE_GALLERY\' | translate}}\n' +
    '                </h3>\n' +
    '                <div class="w-stretch divider-bottom layout-row layout-start-center">\n' +
    '                    <input class="no-divider rm8 text-subhead1 flex"\n' +
    '                           ng-disabled="transaction.busy()"\n' +
    '                           ng-model="$search" ng-keypress="onKeyPress($event)"\n' +
    '                           placeholder="{{::\'SEARCH_PICTURES\' | translate}}"\n' +
    '                           type="text" tabindex="1">\n' +
    '\n' +
    '                    <md-button class="md-icon-button md-icon-button-square p0 pip-search-button md-primary"\n' +
    '                               ng-click="onSearchClick()"\n' +
    '                               ng-hide="optionDefault"\n' +
    '                               tabindex="-1" aria-label="SEARCH">\n' +
    '                        <md-icon class="text-opacity md-primary" md-svg-icon="icons:search-square "></md-icon>\n' +
    '                    </md-button>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="pip-content flex"\n' +
    '                 ng-show="$images.length > 0">\n' +
    '                <div class="pip-image-container"\n' +
    '                     ng-click="onImageClick(image)"\n' +
    '                     ng-repeat="image in $images track by $index"\n' +
    '                     ng-class="{\'checked\': image.checked}"\n' +
    '                     tabindex="{{ $index + 2 }}">\n' +
    '\n' +
    '                    <pip-picture pip-src="image.thumbnail"\n' +
    '                                 pip-default-icon="icon-images"\n' +
    '                                 pip-rebind="true">\n' +
    '                    </pip-picture>\n' +
    '                    <div class="pip-checked-cover"></div>\n' +
    '                    <div class="pip-checkbox-backdrop">\n' +
    '                        <md-checkbox md-no-ink\n' +
    '                                     ng-model="image.checked"\n' +
    '                                     ng-click="image.checked = !image.checked"\n' +
    '                                     aria-label="CHECKED">\n' +
    '                        </md-checkbox>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div class="pip-no-images w-stretch layout-column layout-align-center-center flex"\n' +
    '                 ng-show="$images.length == 0">\n' +
    '                <img src="images/add_from_image_library.svg" width="200" height="200">\n' +
    '                <p class="text-secondary opacity-secondary text-center">{{\'IMAGE_START_SEARCH\' | translate}}</p>\n' +
    '                <!--<md-icon class="text-grey" md-svg-icon="icons:images"></md-icon> -->\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </md-dialog-content>\n' +
    '    <div class="pip-footer">\n' +
    '        <md-button  ng-click="onCancelClick()"\n' +
    '                    ng-hide="transaction.busy()"\n' +
    '                    aria-label="{{ ::\'CANCEL\' | translate }}"\n' +
    '                    tabindex="{{ $images.length + 3 }}">\n' +
    '                <span class="text-grey">\n' +
    '                    {{ ::\'CANCEL\' | translate }}\n' +
    '                </span>\n' +
    '        </md-button>\n' +
    '        <md-button ng-if="transaction.busy()" ng-click="onStopSearchClick()" class="md-raised md-warn m0"\n' +
    '                   tabindex="5" aria-label="ABORT"\n' +
    '                   pip-test="button-cancel">\n' +
    '            {{::\'CANCEL\' | translate}}\n' +
    '        </md-button>\n' +
    '        <md-button  class="md-accent"\n' +
    '                    ng-hide="transaction.busy()"\n' +
    '                    ng-disabled="addButtonDisabled()"\n' +
    '                    ng-click="onAddClick()"\n' +
    '                    aria-label="{{ ::\'ADD\' | translate }}"\n' +
    '                    tabindex="{{ $images.length + 4 }}">\n' +
    '\n' +
    '            {{ ::\'ADD\' | translate }}\n' +
    '        </md-button>\n' +
    '    </div>\n' +
    '</md-dialog>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipPictures.Templates');
} catch (e) {
  module = angular.module('pipPictures.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('picture_list_edit/picture_list_edit.html',
    '<div pip-focused>\n' +
    '	<div class="pip-picture-upload pointer pip-focusable"\n' +
    '		 ng-class="{\'pip-picture-error\': item.error}"\n' +
    '		 ng-keydown="onKeyDown($event, item)"\n' +
    '		 tabindex="{{ ngDisabled() ? -1 : 0 }}"\n' +
    '		 ng-repeat="item in control.items | filter: filterItem">\n' +
    '\n' +
    '		<div class="pip-default-icon" ng-hide="item.loaded || item.error">\n' +
    '			<md-icon  pip-cancel-drag="true" class="pip-picture-icon" md-svg-icon="icons:{{icon}}"></md-icon>\n' +
    '		</div>\n' +
    '		<div class="pip-default-text" ng-show="item.error">\n' +
    '			<!--span style="color: red">{{ \'ERROR_IMAGE_PRELOADING\' | translate}}</span-->\n' +
    '			<md-icon  pip-cancel-drag="true" md-svg-icon="icons:warn-circle"></md-icon>\n' +
    '		</div>\n' +
    '		<img ng-src="{{::item.url}}"\n' +
    '			 pip-cancel-drag="true"\n' +
    '			 ng-hide="item.error"\n' +
    '			 ng-class="{ \'pip-image-new\': item.state == \'added\' }"\n' +
    '			 ui-event="{ error: \'onImageError($event, item)\', load: \'onImageLoad($event, item)\' }">\n' +
    '\n' +
    '		<md-button ng-click="onDeleteClick(item)"\n' +
    '				   ng-disabled="ngDisabled() || control.uploading" tabindex="-1"\n' +
    '				   aria-label="delete"\n' +
    '				   class="md-icon-button">\n' +
    '\n' +
    '			<md-icon  pip-cancel-drag="true" md-svg-icon="icons:cross"></md-icon>\n' +
    '		</md-button>\n' +
    '		<md-progress-linear md-mode="indeterminate" ng-show="item.uploading" value="{{ item.progress }}">\n' +
    '		</md-progress-linear>\n' +
    '	</div>\n' +
    '\n' +
    '	<button class="pip-picture-upload pip-picture-drop pip-focusable"\n' +
    '			pip-add-image\n' +
    '		    ng-focus="onFocus()"\n' +
    '	        ng-blur="onBlur()"\n' +
    '			pip-changed="readItemLocally(url, file)"\n' +
    '			ng-disabled="ngDisabled() || control.uploading">\n' +
    '\n' +
    '		<div class="pip-default-icon">\n' +
    '			<md-icon  pip-cancel-drag="true" class="pip-picture-icon" md-svg-icon="icons:{{icon}}"></md-icon>\n' +
    '		</div>\n' +
    '		<div class="pip-default-text">\n' +
    '			<span>{{text | translate}}</span>\n' +
    '		</div>\n' +
    '	</button>\n' +
    '	<div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipPictures.Templates');
} catch (e) {
  module = angular.module('pipPictures.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('picture_edit/picture_edit.html',
    '<div class="pip-picture-upload pip-picture-drop md-focused"\n' +
    '	 ng-keydown="onKeyDown($event)"\n' +
    ' 	 tabindex="{{ ngDisabled() || control.uploading ? -1 : 0 }}"\n' +
    '	 pip-changed="readItemLocally(url, file)"\n' +
    '	 ng-disabled="ngDisabled()"\n' +
    '	 pip-multi="false"\n' +
    '	 ng-focus="onFocus()"\n' +
    '	 ng-blur="onBlur()"\n' +
    '	 pip-add-image>\n' +
    '\n' +
    '	<div class="pip-default-icon" ng-show="empty()">\n' +
    '		<md-icon  class="pip-picture-icon" md-svg-icon="icons:{{icon}}"></md-icon>\n' +
    '	</div>\n' +
    '	<div class="pip-default-text" ng-show="empty()">\n' +
    '		<span>{{text | translate}}</span>\n' +
    '	</div>\n' +
    '\n' +
    '	<img class="pip-picture-image"\n' +
    '		 ng-src="{{control.url}}"\n' +
    '		 ng-show="!empty()"\n' +
    '		 ng-class="{ \'pip-image-new\': isUpdated(), \'cursor-default\' : ngDisabled() || control.uploading }"\n' +
    ' 		 ui-event="{ error: \'onImageError($event)\', load: \'onImageLoad($event)\' }">\n' +
    '\n' +
    '	<md-button class="md-icon-button"\n' +
    '			   ng-click="onDeleteClick($event)"\n' +
    '			   tabindex="-1" aria-label="delete"\n' +
    '			   ng-hide="empty() || ngDisabled()"\n' +
    '			   ng-disabled="ngDisabled() || control.uploading">\n' +
    '		<md-icon  md-svg-icon="icons:cross"></md-icon>\n' +
    '	</md-button>\n' +
    '\n' +
    '	<md-progress-linear ng-show="control.uploading"\n' +
    '						ng-value="control.progress">\n' +
    '	</md-progress-linear>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipPictures.Templates');
} catch (e) {
  module = angular.module('pipPictures.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('picture_url_dialog/picture_url_dialog.html',
    '<!--\n' +
    '@file Picture URL dialog content\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-dialog class="pip-dialog pip-picture-url-dialog pip-picture-dialog layout-column"\n' +
    '           md-theme="{{theme}}">\n' +
    '\n' +
    '    <md-dialog-content class="pip-body lp0 rp0 tp0 pip-scroll">\n' +
    '        <div class="pip-header bm16 layout-row layout-align-start-center">\n' +
    '            <md-button  ng-click="onCancelClick()" class="md-icon-button lm0"\n' +
    '                        aria-label="{{ ::\'CANCEL\' | translate }}">\n' +
    '                <md-icon class="text-grey" md-svg-icon="icons:arrow-left"></md-icon>\n' +
    '            </md-button>\n' +
    '            <h3 class="text-title m0">\n' +
    '                {{ ::\'PICTURE_FROM_WEBLINK\' | translate}}\n' +
    '            </h3>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="pip-content">\n' +
    '            <md-input-container md-no-float class="w-stretch text-subhead1">\n' +
    '                <input type="text" ng-model="url" ng-change="checkUrl()" placeholder="{{:: \'LINK_PICTURE\' | translate}}"/>\n' +
    '            </md-input-container>\n' +
    '\n' +
    '            <div class="w-stretch layout-row layout-align-center-center"\n' +
    '                 ng-hide="invalid">\n' +
    '                <img id="url_image"/>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="pip-no-images layout-row layout-align-center-center" ng-show="invalid">\n' +
    '                <md-icon class="text-grey" md-svg-icon="icons:images"></md-icon>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '    </md-dialog-content>\n' +
    '    <div class="pip-footer">\n' +
    '        <md-button ng-click="onCancelClick()" aria-label="{{ ::\'CANCEL\' | translate }}">\n' +
    '            {{ ::\'CANCEL\' | translate }}\n' +
    '        </md-button>\n' +
    '\n' +
    '        <md-button class="md-accent" ng-click="onAddClick()" ng-disabled="invalid"\n' +
    '                   aria-label="{{ ::\'ADD\' | translate }}">\n' +
    '            {{ ::\'ADD\' | translate }}\n' +
    '        </md-button>\n' +
    '    </div>\n' +
    '</md-dialog>');
}]);
})();
