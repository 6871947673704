(function(module) {
try {
  module = angular.module('pipGuidance.Templates');
} catch (e) {
  module = angular.module('pipGuidance.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('guidance/guidance_dialog.html',
    '<!--\n' +
    '@file Guidance dialog content\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-dialog class="pip-dialog pip-guidance-dialog layout-column" width="768" md-theme="{{theme}}">\n' +
    '    <div class="pip-header layout-row">\n' +
    '        <h3 class="rm16 flex">{{title | translate}}</h3>\n' +
    '        <md-button class="pip-dialog-close" ng-click="onCancel()" \n' +
    '            aria-label="{{::\'CLOSE\' | translate}}">\n' +
    '            <span class="icon-cross"></span>\n' +
    '        </md-button>\n' +
    '    </div>\n' +
    '    <div class="pip-body">\n' +
    '        <div class="pip-content">\n' +
    '            <pip-picture pip-src="imageUrl" ng-hide="!imageUrl || imageUrl == \'\'" class="bm16 center-block"\n' +
    '                ng-style="{ width: imageWidth, height: imageHeight, display: \'block\' }">\n' +
    '            </pip-picture>\n' +
    '\n' +
    '            <div class="bm16" pip-translate-html="{{::content}}"></div>\n' +
    '\n' +
    '            <md-button class="md-raised md-accent w-stretch" ng-click="onAction()"\n' +
    '                       ng-hide="!action || action==\'\'"\n' +
    '                       arial-label="{{::action | translate}}">\n' +
    '                {{::action | translate}}\n' +
    '            </md-button>\n' +
    '\n' +
    '            <md-checkbox aria-label="{{\'DO_NOT_SHOW\' | translate}}" class="w-stretch m0 tm16 regular_14"\n' +
    '                         ng-model="hideToggle" ng-change="onHideToggle()" ng-show="showHideToggle"\n' +
    '                         aria-label="{{::\'GUIDANCE_DO_NOT_SHOW\' | translate}}">\n' +
    '                {{::\'GUIDANCE_DO_NOT_SHOW\' | translate}}\n' +
    '            </md-checkbox>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    </div>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipGuidance.Templates');
} catch (e) {
  module = angular.module('pipGuidance.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('intro_guidance/intro_guidance_dialog.html',
    '<md-dialog class="pip-dialog pip-guidance-dialog pip-guide-preview layout-column" md-theme="{{theme}}">\n' +
    '    <div ng-if="!$routing" ng-swipe-left="onNextPage()" ng-swipe-right="onBackPage()"\n' +
    '         class="h-stretch flex layout layout-column {{\'bg-\' + data.pages[number].color}} ">\n' +
    '        <div class="layout layout-row layout-align-space-between-center layout-align-xs-center-center w-stretch pip-guide-page">\n' +
    '            <md-button ng-click="onBackPage()" class=" lm16 hide-xs" aria-label="BACK"\n' +
    '                       ng-disabled="transaction.busy() || number == 0">\n' +
    '                <md-icon md-svg-icon="icons:arrow-left" class="pip-arrow-button"\n' +
    '                         ng-class="{\'opacity-disabled\' :number == 0}"></md-icon>\n' +
    '            </md-button>\n' +
    '            <div class="layout layout-column layout-align-center-center bm16">\n' +
    '                <pip-collage class="flex-fixed" ng-if="data.pages[number].pic_id && (!data.pictures || !data.pictures[number])"\n' +
    '                             pip-picture-ids="data.pages[number].picId" pip-unique-code="data.id"\n' +
    '                             pip-multiple="false" pip-open="false" pip-rebind="true">\n' +
    '                </pip-collage>\n' +
    '                <div class="pip-pic" ng-if="!data.pages[number].pic_id || data.pictures[number]"\n' +
    '                     style="background-image: url({{data.pictures[number]}})"></div>\n' +
    '\n' +
    '                <div class="layout layout-column layout-align-center-center pip-text">\n' +
    '                    <p class="pip-preview-title" ng-if="data.pages[number].title[ln]">\n' +
    '                        {{data.pages[number].title[ln]}}</p>\n' +
    '\n' +
    '                    <p class="pip-preview-content" ng-if="data.pages[number].content[ln]">\n' +
    '                        {{data.pages[number].content[ln]}}</p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <md-button ng-click="onNextPage()" class="rm16 hide-xs" aria-label="DOWN"\n' +
    '                       ng-disabled="transaction.busy() || number == data.pages.length - 1">\n' +
    '                <md-icon md-svg-icon="icons:arrow-right" class="pip-arrow-button"\n' +
    '                         ng-class="{\'opacity-disabled\' : number == data.pages.length - 1}"></md-icon>\n' +
    '            </md-button>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class=" flex-fixed flex w-stretch pip-guide-page-footer">\n' +
    '\n' +
    '\n' +
    '            <div  class="layout-row layout-align-center-center" ng-if="data.pages.length > 1">\n' +
    '                <md-icon ng-repeat="radio in data.pages" ng-click="onChangePage($index)" class="pip-radio-button "\n' +
    '                         md-svg-icon="{{radio != data.pages[number] ? \'icons:radio-off\' : \'icons:circle\'}}">\n' +
    '                </md-icon>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="h64 layout-row layout-align-xs-space-between-center layout-align-center-center">\n' +
    '\n' +
    '                <md-button ng-click="onBackPage()" class="lm16" ng-if="$mdMedia(\'xs\')" aria-label="BACK"\n' +
    '                           ng-disabled="transaction.busy() || number == 0">\n' +
    '                    <md-icon md-svg-icon="icons:arrow-left" class="pip-arrow-button"\n' +
    '                             ng-class="{\'opacity-disabled\' :number == 0}"></md-icon>\n' +
    '                </md-button>\n' +
    '\n' +
    '                <md-button ng-click="onClose()"\n' +
    '                           class="pip-button-got rm8 lm8\n' +
    '                                       {{number == data.pages.length - 1  ? \'fg-\' + data.pages[number].color : \'bg-\' + data.pages[number].color}}"\n' +
    '                           ng-class="{\'md-raised\':  number == data.pages.length - 1}"\n' +
    '                           aria-label="NEXT"\n' +
    '                           ng-disabled="transaction.busy()">\n' +
    '                    GOT IT !\n' +
    '                </md-button>\n' +
    '\n' +
    '                <md-button ng-click="onNextPage()" class="rm16 "  ng-if="$mdMedia(\'xs\')"  aria-label="DOWN"\n' +
    '                           ng-disabled="transaction.busy() || number == data.pages.length - 1">\n' +
    '                    <md-icon md-svg-icon="icons:arrow-right" class="pip-arrow-button"\n' +
    '                             ng-class="{\'opacity-disabled\' : number == data.pages.length - 1}"></md-icon>\n' +
    '                </md-button>\n' +
    '            </div>\n' +
    '\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</md-dialog>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipGuidance.Templates');
} catch (e) {
  module = angular.module('pipGuidance.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('tips/tip.template.html',
    '<div ng-if="title" class=\'pip-title p24-flex flex-fixed bp16\'>\n' +
    '    {{ title | translate }}\n' +
    '</div>\n' +
    '\n' +
    '<div class=\'pip-content pip-popover-content lp24-flex rp24-flex text-body1 bm64 pip-scroll\'\n' +
    '     ng-class="{\'tm24\' : !title }">\n' +
    '    <div ng-if="image && $mdMedia(\'gt-xs\')" class="pip-pic"></div>\n' +
    '    <pip-markdown pip-text="content" pip-rebind="true"></pip-markdown>\n' +
    '</div>\n' +
    '\n' +
    '<div class="pip-footer lm24-flex rm24-flex position-bottom layout-row layout-align-start-center">\n' +
    '    <a ng-if="link" target="_blank" href="{{ link }}" class="text-body2 flex">\n' +
    '        {{:: \'MORE_URL\' | translate }}\n' +
    '    </a>\n' +
    '    <div  ng-if="!link" class="flex"></div>\n' +
    '\n' +
    '    <md-button ng-click=\'onNextClick()\' class="rm0">\n' +
    '        {{:: \'NEXT\' | translate }}\n' +
    '    </md-button>\n' +
    '\n' +
    '</div>');
}]);
})();
