(function(module) {
try {
  module = angular.module('pipDocuments.Templates');
} catch (e) {
  module = angular.module('pipDocuments.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('document_list/document_list.html',
    '<!--\n' +
    '@file Document list control content\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<md-button class="pip-documents-name"\n' +
    '           ng-class="{\'lp24-flex rp16\': pipDocumentIcon}"\n' +
    '           ng-click="onTitleClick($event); onResize()"\n' +
    '           aria-label="RESIZE">\n' +
    '\n' +
    '    <div class="layout-align-start-center layout-row w-stretch">\n' +
    '        <md-icon md-svg-icon="icons:document" ng-class="{\'pip-icon\': pipDocumentIcon}" ng-if="pipDocumentIcon"></md-icon>\n' +
    '        <span class="pip-documents-text">\n' +
    '            {{documents.length}} {{::\'DOCUMENTS_ATTACHED\' | translate}}\n' +
    '        </span>\n' +
    '\n' +
    '        <md-icon class="icon-up" md-svg-icon="icons:triangle-up"></md-icon>\n' +
    '        <md-icon class="icon-down" md-svg-icon="icons:triangle-down"></md-icon>\n' +
    '    </div>\n' +
    '</md-button>\n' +
    '<div pip-focused class="pip-documents-container bm8"  ng-class="{\'lp24-flex rp24-flex\': pipDocumentIcon}">\n' +
    '    <md-button class="pip-document-download md-primary"\n' +
    '               ng-class="{\'pip-focusable\' : !ngDisabled()}"\n' +
    '               href="{{::documentUrl(document)}}"\n' +
    '               target="_blank"\n' +
    '               ng-disabled="ngDisabled() || document.error"\n' +
    '               ng-repeat="document in documents track by document.file_id"\n' +
    '               aria-label="DOCUMENT">\n' +
    '\n' +
    '        <div class="pip-default-icon">\n' +
    '            <md-icon md-svg-icon="icons:{{::documentList.icon}}"></md-icon>\n' +
    '        </div>\n' +
    '        <div class="pip-document-title">\n' +
    '            {{::document.file_name}}\n' +
    '        </div>\n' +
    '    </md-button>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('pipDocuments.Templates');
} catch (e) {
  module = angular.module('pipDocuments.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('document_list/document_list_collapse.html',
    '<!--\n' +
    '@file Document list control content (collapsable version)\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '\n' +
    '<div class="pip-documents-name" ng-click="onTitleClick($event); onResize()">\n' +
    '    <span class="pip-documents-text">\n' +
    '        {{documents.length}} {{::\'DOCUMENTS_ATTACHED\' | translate}}\n' +
    '    </span>\n' +
    '\n' +
    '    <md-icon class="icon-up" md-svg-icon="icons:triangle-up"></md-icon>\n' +
    '    <md-icon class="icon-down" md-svg-icon="icons:triangle-down"></md-icon>\n' +
    '</div>\n' +
    '<div pip-focused class="pip-documents-container bm8">\n' +
    '    <md-button class="pip-document-download pip-focusable md-primary"\n' +
    '               href="{{::document.url}}"\n' +
    '               target="_blank"\n' +
    '               ng-repeat="document in documents track by document.file_id"\n' +
    '               aria-label="DOCUMENT">\n' +
    '        <div class="pip-default-icon">\n' +
    '            <md-icon md-svg-icon="icons:{{::icon}}"></md-icon>\n' +
    '        </div>\n' +
    '        <div class="pip-document-title">\n' +
    '            {{::document.file_name}}\n' +
    '        </div>\n' +
    '    </md-button>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('pipDocuments.Templates');
} catch (e) {
  module = angular.module('pipDocuments.Templates', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('document_list_edit/document_list_edit.html',
    '<!--\n' +
    '@file Document list edit control content\n' +
    '@copyright Digital Living Software Corp. 2014-2016\n' +
    '-->\n' +
    '<div pip-focusable>\n' +
    '	<div class="pip-document-upload pointer md-primary "\n' +
    '		 ng-class="{\'pip-focusable\' : !ngDisabled(), \'pip-item-error\' : item.state == \'error\'}"\n' +
    '		 ng-keydown="onKeyDown($event, item)"\n' +
    '		 tabindex="{{ ngDisabled() ? -1 : 0 }}"\n' +
    '		 ng-repeat="item in control.items | filter: filterItem">\n' +
    '\n' +
    '		<div class="pip-default-icon"\n' +
    '			 ng-class="{ \'pip-document-new\': item.id == null }">\n' +
    '			<md-icon pip-cancel-drag="true" class="md-primary" ng-if="item.state == \'original\' || item.state == \'added\'"\n' +
    '					 md-svg-icon="icons:{{::documentList.icon}}">\n' +
    '			</md-icon>\n' +
    '			<md-icon pip-cancel-drag="true" class="md-warn" ng-if="item.state == \'error\'"\n' +
    '					 md-svg-icon="icons:{{::documentList.iconError}}">\n' +
    '			</md-icon>\n' +
    '		</div>\n' +
    '\n' +
    '		<div class="pip-document-title" pip-cancel-drag="true">\n' +
    '			{{::item.name}}\n' +
    '		</div>\n' +
    '		<md-button ng-click="onDelete(item)"\n' +
    '				   ng-disabled="ngDisabled() || control.uploading"\n' +
    '				   tabindex="-1"\n' +
    '				   ng-hide="ngDisabled()"\n' +
    '				   class="md-icon-button" aria-label="DELETE">\n' +
    '\n' +
    '			<md-icon md-svg-icon="icons:cross" pip-cancel-drag="true"></md-icon>\n' +
    '		</md-button>\n' +
    '		<md-progress-linear ng-show="item.uploading" ng-value="item.progress"></md-progress-linear>\n' +
    '	</div>\n' +
    '	\n' +
    '	<button class="pip-document-upload pip-document-drop "\n' +
    '			ng-class="{\'pip-focusable\' : !ngDisabled()}"\n' +
    '			ng-keydown="onKeyDown($event)" tabindex="0"\n' +
    '			ng-file-drop ng-file-select ng-file-change="onSelect($files)"\n' +
    '			ng-multiple="true"\n' +
    '			ng-disabled="ngDisabled() || control.uploading"\n' +
    '			aria-label="UPLOAD">\n' +
    '\n' +
    '		<div class="pip-default-icon">\n' +
    '			<md-icon pip-cancel-drag="true" md-svg-icon="icons:{{::documentList.icon}}"></md-icon>\n' +
    '		</div>\n' +
    '		<div class="pip-default-text">\n' +
    '			<span>\n' +
    '				{{documentList.text | translate}}\n' +
    '			</span>\n' +
    '		</div>\n' +
    '	</button>\n' +
    '	<div class="clearfix"></div>\n' +
    '</div>\n' +
    '');
}]);
})();
